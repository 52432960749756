import React, {useEffect, useState} from 'react';
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useCookies} from "react-cookie";
import "./styles.css";
import {COOKIE_LIFETIME_SECONDS} from "consts/LanguageConsts";

const LanguageSelector = () => {
    const [cookies, setCookie] = useCookies(['language']);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(cookies.language || 'en');
    useEffect(() => {
        if (cookies.language) {
            setSelectedLanguage(cookies.language);
        }
    }, [cookies.language]);
    const changeLanguage = (event: SelectChangeEvent<string>) => {
        setCookie('language', event.target.value, {maxAge: COOKIE_LIFETIME_SECONDS, path: "/"});
        window.location.reload();
    }
    return (
        <div className="language-selector">
            <Select
                value={selectedLanguage}
                onChange={changeLanguage}
                size="small"
            >
                <MenuItem value="en">
                    <div className="language-selector__image">
                        <img alt="EN" src="/static/countries/en.svg"/>
                    </div>
                </MenuItem>
                <MenuItem value="et">
                    <div className="language-selector__image">
                        <img alt="EE" src="/static/countries/ee.svg"/>
                    </div>
                </MenuItem>
                <MenuItem value="lv">
                    <div className="language-selector__image">
                        <img alt="LV" src="/static/countries/lv.svg"/>
                    </div>
                </MenuItem>
            </Select>
        </div>
    );
};

export default LanguageSelector;
