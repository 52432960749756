import {CountryType} from "consts/CountryConsts";
import {SupportedLanguage} from "consts/LanguageConsts";
import {en} from "locales/static/countries/en";
import {et} from "locales/static/countries/et";
import {lv} from "locales/static/countries/lv";

export type CountryLocaleModel = Record<CountryType, string>;

type CountriesLocaleModel = Record<SupportedLanguage, CountryLocaleModel>;

const translations: CountriesLocaleModel = {
    en,
    et,
    lv,
};

export const getDefaultTranslation = (country: CountryType) => {
    return translations.en[country];
}

export const getCountryTranslation = (country?: CountryType, languageIso?: SupportedLanguage): string | undefined => {
    if (!country || !languageIso) {
        return;
    }
    const languageCountries = translations[languageIso];
    if (languageCountries) {
        return languageCountries[country];
    }
    return;
}
