import React from 'react';
import NewsLetterSignup from "components/NewsLetterSignup";
import PageWithHeader from "components/common/PageWithHeader";

const NewsLetterSignupPage = () => {
    return (
        <PageWithHeader>
            <NewsLetterSignup />
        </PageWithHeader>
    );
};

export default NewsLetterSignupPage;
