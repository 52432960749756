import React from "react";
import {useQuery} from "@tanstack/react-query";
import PopupSearchResponse from "api/model/PopupSearchResponse";
import {searchPopups} from "api/actions/PopupActions";
import SearchMap from "components/Search/SearchMap";
import SearchResults from "components/Search/SearchResults";
import {FilterFormModel} from "components/Search/SearchFilter";
import PopupList from "components/Search/PopupSearch/PopupList";
import PopupMap from "components/Search/PopupSearch/PopupMap";
import {doesHaveFilters} from "lib/SearchHelper";

interface PopupSearchProps {
    searchFilter?: FilterFormModel,
}

const PopupSearch = (props: PopupSearchProps) => {
    const {searchFilter} = props;
    const {
        data,
        isLoading,
    } = useQuery<PopupSearchResponse>({
        queryKey: ['popups-search-query', searchFilter],
        queryFn: () => searchPopups(searchFilter),
    });
    return (
        <SearchResults
            isLoading={isLoading}
            list={<PopupList popups={data?.popups} properties={data?.properties}/>}
            map={(
                <SearchMap>
                    {setMarkerInfo => <PopupMap hasUserFilter={doesHaveFilters(searchFilter)} setMarkerInfo={setMarkerInfo} searchResponse={data}/>}
                </SearchMap>
            )}
        />
    )
}

export default PopupSearch;