import React, {useEffect, useState} from "react";
import {MarkerClusterer,} from "@googlemaps/markerclusterer";
import {useMap} from "@vis.gl/react-google-maps";
import {MarkerInfo} from "components/Search/SearchMap";
import EventMapMarker from "components/Search/EventSearch/EventMapMarker";
import EventModel from "api/model/EventModel";

interface EventMapMarkerClustererProps {
    events: EventModel[],
    setMarkerInfo: (info: MarkerInfo | null) => void,
}

export interface EventMarkerModel {
    marker: google.maps.marker.AdvancedMarkerElement,
    id: string,
}

const EventMapMarkerClusterer = (props: EventMapMarkerClustererProps) => {
    const {
        events,
        setMarkerInfo,
    } = props;
    const map = useMap();
    const [markers, setMarkers] = useState<EventMarkerModel[]>([]);
    const setMarker = (marker: EventMarkerModel) => {
        setMarkers(prevState => {
            return [...prevState, marker];
        });
    }
    const removeMarker = (id: string) => {
        setMarkers(prevState => {
            return prevState.filter(marker => marker.id !== id);
        });
    }
    useEffect(() => {
        const cluster = new MarkerClusterer({
            map,
            markers: markers.map(marker => marker.marker),
            onClusterClick: (event, cluster, map) => {
                if (cluster.bounds) {
                    map.fitBounds(cluster.bounds, {
                        bottom: 200,
                        top: 200,
                        right: 50,
                        left: 50,
                    });
                }
            }
        });
        return () => {
            cluster.setMap(null);
            cluster.unbindAll();
            cluster.clearMarkers();
        };
    }, [map, markers]);
    return (
        <>
            {
                events?.map(event => (
                    <EventMapMarker
                        event={event}
                        key={event.id}
                        setRef={setMarker}
                        showInfo={setMarkerInfo}
                        removeRef={removeMarker}
                    />
                ))
            }
        </>
    );
}

export default EventMapMarkerClusterer;
