import React from 'react';
import {EventPriceModel} from "api/model/EventModel";
import {useTranslation} from "react-i18next";
import ItemTag from "components/common/ItemTag";
import "./styles.css";
import {formatPrice} from "lib/PriceHelper";

interface EventDetailsPricesProps {
    prices: EventPriceModel[]
}

const EventDetailsPrices = (props: EventDetailsPricesProps) => {
    const {t} = useTranslation();
    const {prices} = props;
    const daily = prices.find(price => price.priceType === "PER_DAY");
    const weekly = prices.find(price => price.priceType === "PER_WEEK");
    const monthly = prices.find(price => price.priceType === "PER_MONTH");
    const fixed = prices.find(price => price.priceType === "FIXED");
    const showTaxDisclaimer = daily?.includesVAT || weekly?.includesVAT || monthly?.includesVAT || fixed?.includesVAT;
    return (
        <div className="event-details-prices">
            <div className="event-details-prices__tags">
                {daily && (
                    <ItemTag>
                        {daily.currency}{formatPrice(daily.price)}&nbsp;{t('popup.details.page.price.per.day')}{daily.includesVAT && '*'}
                    </ItemTag>
                )}
                {weekly && (
                    <ItemTag>
                        {weekly.currency}{formatPrice(weekly.price)}&nbsp;{t('popup.details.page.price.per.week')}{weekly.includesVAT && '*'}
                    </ItemTag>
                )}
                {monthly && (
                    <ItemTag>
                        {monthly.currency}{formatPrice(monthly.price)}&nbsp;{t('popup.details.page.price.per.month')}{monthly.includesVAT && '*'}
                    </ItemTag>
                )}
                {fixed && (
                    <ItemTag>
                        {fixed.currency}{formatPrice(fixed.price)}{fixed.includesVAT && '*'}
                    </ItemTag>
                )}
            </div>
            {
                showTaxDisclaimer && (
                    <div className="event-details-prices__vat">
                        {t('popup.details.page.price.including.vat')}
                    </div>
                )
            }
        </div>
    );
}

export default EventDetailsPrices;