import FileModel, {FileErrorType} from "api/model/FileModel";
import axios from 'axios';

const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024; // 20 mb

const PROHIBITED_FILE_EXTENSIONS = [".exe", ".app", ".sh", ".jar", ".java", ".dmg"];

export const uploadTempFile = (file: FileModel): Promise<FileModel> => {
    let errorType: FileErrorType | undefined = undefined;
    for (const ext of PROHIBITED_FILE_EXTENSIONS) {
        if (file.file.name.endsWith(ext)) {
            errorType = "INVALID_TYPE";
            break;
        }
    }
    if (file.file.size > MAX_FILE_SIZE_BYTES) {
        errorType = "LARGE_FILE";
    }

    if (errorType) {
        return new Promise<FileModel>((_, reject) => {
            reject({
                ...file,
                errorType,
            } as FileModel);
        });
    }

    const formData = new FormData();
    formData.append("file", file.file, file.file.name);
    formData.append("originalname", file.file.name);
    return axios
        .post<string>(`/api/files`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(res => ({
            ...file,
            uuid: res.data,
        }))
        .catch(() => ({
            ...file,
            errorType: "UNKNOWN",
        }));
};

export const uploadFiles = (files: FileModel[]): Promise<PromiseSettledResult<FileModel>[]> => {
    const promises = [];
    for (const file of files) {
        let errorType: FileErrorType | undefined = undefined;
        for (const ext of PROHIBITED_FILE_EXTENSIONS) {
            if (file.file.name.endsWith(ext)) {
                errorType = "INVALID_TYPE";
            }
        }
        if (file.file.size > MAX_FILE_SIZE_BYTES) {
            errorType = "LARGE_FILE";
        }

        if (errorType) {
            promises.push(
                new Promise<FileModel>((_, reject) => {
                    reject({
                        ...file,
                        errorType
                    } as FileModel);
                }),
            );
        } else {
            const formData = new FormData();
            formData.append("file", file.file, file.file.name);
            formData.append("originalname", file.file.name);
            promises.push(
                axios
                    .post<string>(`/api/files`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(res => ({
                        ...file,
                        uuid: res.data,
                    } as FileModel))
                    .catch(() => ({
                        ...file,
                        errorType: "UNKNOWN",
                    } as FileModel)),
            );
        }
    }
    return Promise.allSettled(promises);
}