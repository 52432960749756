import React from "react";
import {theme} from "theme";
import {useTranslation} from "react-i18next";
import SignupForm from "components/NewsLetterSignup/SignupForm";
import "./styles.css";

const NewsLetterSignup = () => {
    const {t} = useTranslation();
    return (
        <div className="news-letter-signup">
            <span style={{color: theme.color.secondary}} className="news-letter-signup__header">
                {t('news.letter.page.header')}
            </span>
            <SignupForm/>
        </div>
    );
};

export default NewsLetterSignup;