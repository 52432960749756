import React from "react";
import EventModel from "api/model/EventModel";
import NoResults from "components/Search/EventSearch/EventList/NoResults";
import EventListItem from "components/Search/EventSearch/EventList/EventListItem";
import "./styles.css";

interface EventListProps {
    events?: EventModel[],
}

const EventList = (props: EventListProps) => {
    const {events = []} = props;
    return (
        <div className="event-search-list">
            {
                events.length > 0 ? events.map(event => (
                    <EventListItem key={event.id} event={event}/>
                )) : <NoResults/>
            }
        </div>
    );
}

export default EventList;