import React from 'react';
import environment from "lib/EnvHelper";
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import PropertyModel from "api/model/PropertyModel";
import "./styles.css";

interface PopupDetailsMapProps {
    property: PropertyModel
}

const PopupDetailsMap = (props: PopupDetailsMapProps) => {
    const {
        property: {
            location,
            name,
        }
    } = props;
    return (
        <div className="popup-details-map">
            <APIProvider apiKey={environment.googleMapsKey}>
                <Map
                    defaultZoom={15}
                    mapTypeControl={false}
                    mapId="287a9dc34d2b207d"
                    defaultCenter={location}
                    fullscreenControl={false}
                    streetViewControl={false}
                >
                    <Marker
                        title={name}
                        position={location}
                    />
                </Map>
            </APIProvider>
        </div>
    );
}

export default PopupDetailsMap;