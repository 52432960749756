import React from 'react';
import "components/Search/PopupSearch/PopupList/NoResults/styles.css";
import {useTranslation} from "react-i18next";
import {theme} from "theme";

const NoResults = () => {
    const {t} = useTranslation();
    return (
        <div style={{color: theme.color.secondary}} className="popup-search-list-no-results">
            <h1>
                ¯\_(ツ)_/¯
            </h1>
            <p>
                {t('popup.search.list.popups.not.found')}
            </p>
        </div>
    )
}

export default NoResults;