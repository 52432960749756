import React from "react";
import Search from "components/Search";
import PageWithHeader from "components/common/PageWithHeader";
import {useParams, useSearchParams} from "react-router-dom";
import {getSearchFilterModelFromParams} from "lib/SearchHelper";
import {SearchType} from "model/SearchType";

interface SearchPageProps {
    searchType?: SearchType
}

const SearchPage = (props: SearchPageProps) => {
    const {searchType} = props;
    const [searchParams] = useSearchParams();
    const params = useParams();
    const searchModel = getSearchFilterModelFromParams(searchParams, params);
    return (
        <PageWithHeader searchType={searchModel.searchType || "popups"}>
            <Search searchType={searchModel.searchType || searchType} searchFilter={searchModel.formModel}/>
        </PageWithHeader>
    );
};

export default SearchPage;