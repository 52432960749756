type EnvironmentType = 'local' | 'dev' | 'test' | 'live';

interface EnvironmentInterface {
    env: EnvironmentType,
    googleMapsKey: string,
}

const environment: EnvironmentInterface = {
    env: process.env.REACT_APP_PROVENTE_ENV as EnvironmentType || 'local',
    googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
}

export default environment;
