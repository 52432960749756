import React, {useEffect} from 'react';
import {useMap} from "@vis.gl/react-google-maps";
import {getBounds} from "geolib";
import PopupSearchResponse from "api/model/PopupSearchResponse";
import {MarkerInfo} from "components/Search/SearchMap";
import PopupMapMarkerClusterer from "components/Search/PopupSearch/PopupMapMarkerClusterer";
import {useLocationStore} from "store/locationStore";

interface PopupMapProps {
    searchResponse?: PopupSearchResponse,
    hasUserFilter: boolean,
    setMarkerInfo: (info: MarkerInfo | null) => void,
}

const PopupMap = (props: PopupMapProps) => {
    const userCountryIso = useLocationStore(state => state.location?.countryIso) || 'EE';
    const {searchResponse, setMarkerInfo, hasUserFilter} = props;
    const map = useMap();
    useEffect(() => {
        if (searchResponse?.properties) {
            let countryProperties = searchResponse.properties
                .filter(property => property.location.countryIso === userCountryIso);
            if (countryProperties.length < 1 || countryProperties.filter(property => !property.isComingSoon).length < 1) {
                countryProperties = searchResponse.properties;
            }
            const locations = countryProperties
                .filter(property => searchResponse.propertiesPopupCounts[property.id] > 0 || (!hasUserFilter && property.isComingSoon))
                .map((property) => property.location);
            if (locations.length > 0 && map) {
                if (locations.length === 1) {
                    const [location] = locations;
                    map.panTo(location);
                    map.setZoom(15);
                } else {
                    const bounds = getBounds(locations);
                    map.fitBounds({
                        north: bounds.maxLat,
                        south: bounds.minLat,
                        east: bounds.maxLng,
                        west: bounds.minLng,
                    }, {
                        bottom: 200,
                        top: 200,
                        right: 50,
                        left: 50,
                    });
                }
            }
        }
    }, [searchResponse, map]);
    return (
        <PopupMapMarkerClusterer setMarkerInfo={setMarkerInfo} searchResponse={searchResponse}/>
    );
};

export default PopupMap;