import React from 'react';
import cx from 'classnames';
import ErrorModel from "api/model/ErrorModel";
import Page from 'components/common/Page';
import PageHeader from "components/common/PageHeader";
import PageFooter from "components/common/PageFooter";
import ErrorPageContent from "components/common/ErrorPageContent";
import "./styles.css";
import LoadingPageContent from "components/common/LoadingPageContent";
import {SearchType} from "model/SearchType";

interface PageWithSearchProps {
    children: any,
    error?: ErrorModel,
    paddedContent?: boolean,
    isLoading?: boolean,
    searchType?: SearchType
}

const PageWithHeader = (props: PageWithSearchProps) => {
    const {children, error, isLoading, paddedContent, searchType} = props;
    const contentClasses = cx({
        'page__content': true,
        'page__content--padded': paddedContent
    });
    return (
        <Page>
            <PageHeader searchType={searchType}/>
            <div onClick={() => window.dispatchEvent(new Event("CONTENT_CLICK"))} className={contentClasses}>
                {
                    isLoading ? <LoadingPageContent/> : (error ? <ErrorPageContent error={error}/> : children)
                }
            </div>
            <PageFooter/>
        </Page>
    );
}

export default PageWithHeader;