import {CountryLocaleModel} from "locales/static/countries";

export const lv: CountryLocaleModel = {
    EE: "Igaunija",
    LV: "Latvija",
    LT: "Lietuva",
    AD: "Andora",
    AF: "Afganistāna",
    AG: "Antigva un Barbuda",
    AI: "Angilja",
    AL: "Albānija",
    AM: "Armēnija",
    AO: "Angola",
    AQ: "Antarktīda",
    AR: "Argentīna",
    AS: "Amerikāņu Samoa",
    AT: "Austrija",
    AU: "Austrālija",
    AW: "Aruba",
    AX: "Olandes salas",
    AZ: "Azerbaidžāna",
    BA: "Bosnija un Hercegovina",
    BB: "Barbadosa",
    BD: "Bangladeša",
    BE: "Beļģija",
    BF: "Burkinafaso",
    BG: "Bulgārija",
    BH: "Bahreina",
    BI: "Burundi",
    BJ: "Benina",
    BL: "Senbartelmī",
    BM: "Bermudu salas",
    BN: "Bruneja",
    BO: "Bolīvija",
    BQ: "Bonaira, Sintestatius un Saba",
    BR: "Brazīlija",
    BT: "Butāna",
    BV: "Buvē sala",
    BW: "Botsvāna",
    BY: "Baltkrievija",
    BZ: "Beliza",
    CA: "Kanāda",
    CH: "Šveice",
    CI: "Kotdivuāra",
    CL: "Čīle",
    CM: "Kamerūna",
    CN: "Ķīna",
    CO: "Kolumbija",
    CR: "Kostarika",
    CU: "Kuba",
    CV: "Kaboverde",
    CW: "Kirasao",
    CX: "Ziemassvētku sala",
    CY: "Kipra",
    DE: "Vācija",
    DJ: "Džibutija",
    DK: "Dānija",
    DM: "Dominika",
    DZ: "Alžīrija",
    EC: "Ekvadora",
    EG: "Ēģipte",
    EH: "Rietumsahāra",
    ER: "Eritreja",
    ES: "Spānija",
    ET: "Etiopija",
    FI: "Somija",
    FJ: "Fidži",
    FM: "Mikronēzija",
    FR: "Francija",
    GA: "Gabona",
    GD: "Grenāda",
    GE: "Gruzija",
    GF: "Franču Gviāna",
    GG: "Gērnsija",
    GH: "Gana",
    GI: "Gibraltārs",
    GL: "Grenlande",
    GN: "Gvineja",
    GP: "Gvadelupa",
    GQ: "Ekvatoriālā Gvineja",
    GR: "Grieķija",
    GS: "Dienviddžordžija un Dienvidsendviču salas",
    GT: "Gvatemala",
    GU: "Guama",
    GW: "Gvineja-Bisava",
    GY: "Gajāna",
    HK: "Honkonga",
    HN: "Hondurasa",
    HR: "Horvātija",
    HT: "Haiti",
    HU: "Ungārija",
    ID: "Indonēzija",
    IE: "Īrija",
    IL: "Izraēla",
    IM: "Mena sala",
    IN: "Indija",
    IO: "Britu Indijas okeāna teritorija",
    IQ: "Irāka",
    IR: "Irāna",
    IS: "Islande",
    IT: "Itālija",
    JE: "Džērsija",
    JM: "Jamaika",
    JO: "Jordānija",
    JP: "Japāna",
    KE: "Kenija",
    KG: "Kirgizstāna",
    KH: "Kambodža",
    KP: "Ziemeļkoreja",
    KR: "Dienvidkoreja",
    KI: "Kiribati",
    KN: "Sentkitsas un Nevisas",
    KW: "Kuveita",
    KZ: "Kazahstāna",
    LB: "Libāna",
    LC: "Sentlūsija",
    LI: "Lihtenšteina",
    LK: "Šrilanka",
    LR: "Libērija",
    LS: "Lesoto",
    LU: "Luksemburga",
    LY: "Lībija",
    MA: "Maroka",
    MC: "Monako",
    ME: "Melnkalne",
    MF: "Senmartēna",
    MG: "Madagaskara",
    ML: "Mali",
    MM: "Mjanma",
    MN: "Mongolija",
    MO: "Makao",
    MQ: "Martinika",
    MR: "Mauritānija",
    MS: "Montserrata",
    MT: "Malta",
    MU: "Maurīcija",
    MV: "Maldīvija",
    MW: "Malāvija",
    MX: "Meksika",
    MY: "Malaizija",
    MZ: "Mozambika",
    NA: "Namībija",
    NC: "Jaunkaledonija",
    NF: "Norfolkas sala",
    NG: "Nigērija",
    NI: "Nikaragva",
    NO: "Norvēģija",
    NP: "Nepāla",
    NR: "Nauru",
    NU: "Niue",
    NZ: "Jaunzēlande",
    OM: "Omāna",
    PA: "Panama",
    PE: "Peru",
    PF: "Franču Polinēzija",
    PG: "Papua-Jaungvineja",
    PK: "Pakistāna",
    PL: "Polija",
    PM: "Senpjēra un Mikelona",
    PN: "Pitkērna salas",
    PR: "Puertoriko",
    PS: "Palestīna",
    PT: "Portugāle",
    PW: "Palau",
    PY: "Paragvaja",
    QA: "Katara",
    RE: "Reinjona",
    RO: "Rumānija",
    RS: "Serbija",
    RU: "Krievija",
    RW: "Ruanda",
    SA: "Saūda Arābija",
    SB: "Zālamana salas",
    SC: "Seišelu salas",
    SE: "Zviedrija",
    SG: "Singapūra",
    SH: "Svētā Helēna, Asensija un Tristana da Kunja",
    SI: "Slovēnija",
    SJ: "Svalbāra un Jana Majena sala",
    SK: "Slovākija",
    SL: "Sjerraleone",
    SM: "Sanmarīno",
    SN: "Senegāla",
    SO: "Somālija",
    SR: "Surinama",
    SS: "Dienvidsudāna",
    ST: "Santome un Prinsipi",
    SV: "Salvadora",
    SX: "Sintmārtena",
    SY: "Sīrija",
    TD: "Čada",
    TG: "Togo",
    TH: "Taizeme",
    TJ: "Tadžikistāna",
    TK: "Tokelau",
    TL: "Timorleste",
    TM: "Turkmenistāna",
    TN: "Tunisija",
    TO: "Tonga",
    TR: "Turcija",
    TT: "Trinidāda un Tobāgo",
    TV: "Tuvalu",
    TZ: "Tanzānija",
    UA: "Ukraina",
    UG: "Uganda",
    US: "Amerikas Savienotās Valstis",
    UY: "Urugvaja",
    UZ: "Uzbekistāna",
    VC: "Sentvinsenta un Grenadīnas",
    VE: "Venecuēla",
    VG: "Britu Virdžīnu salas",
    VI: "ASV Virdžīnu salas",
    VN: "Vjetnama",
    VU: "Vanuatu",
    WF: "Volisa un Futuna",
    WS: "Samoa",
    YE: "Jemena",
    YT: "Majota",
    ZA: "Dienvidāfrika",
    ZM: "Zambija",
    ZW: "Zimbabve",
    SZ: "Esvatini",
    MK: "Ziemeļmaķedonija",
    PH: "Filipīnas",
    NL: "Nīderlande",
    AE: "Apvienotie Arābu Emirāti",
    MD: "Moldovas Republika",
    GM: "Gambija",
    DO: "Dominikānas Republika",
    SD: "Sudāna",
    LA: "Laosa",
    TW: "Taivāna",
    CG: "Kongo Republika",
    CZ: "Čehija",
    GB: "Apvienotā Karaliste",
    NE: "Nigēra",
    CD: "Kongo Demokrātiskā Republika",
    BS: "Bahamu Sadraudzība",
    CC: "Kokosu (Kīlinga) salas",
    CF: "Centrālāfrikas Republika",
    CK: "Kuka salas",
    FK: "Folklenda salas",
    FO: "Fēru salas",
    HM: "Hērda un Makdonalda salas",
    KM: "Komoru salas",
    KY: "Kaimanu salas",
    MH: "Maršala salas",
    MP: "Ziemeļu Marianas Sadraudzība",
    TC: "Tērksa un Kaikosas salas",
    TF: "Franču Dienvidu un Antarktikas teritorijas",
    UM: "ASV Mazās Aizjūras Salas",
    VA: "Vatikāns",
    XK: "Kosova",
    AN: "Nīderlandes Antiļas",
}
