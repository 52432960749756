import React from 'react';
import {SearchType} from "model/SearchType";
import EventSearch from "components/Search/EventSearch";
import PopupSearch from "components/Search/PopupSearch";
import SearchFilter, {FilterFormModel} from "components/Search/SearchFilter";
import './styles.css';

interface SearchProps {
    searchFilter?: FilterFormModel,
    searchType?: SearchType,
}

const Search = (props: SearchProps) => {
    const {searchFilter, searchType = 'popups'} = props;
    const isPopupSearch = searchType === 'popups';
    return (
        <div className="search">
            <div className="search__header">
                <SearchFilter searchType={searchType} searchFilter={searchFilter}/>
            </div>
            {
                isPopupSearch ? (
                    <PopupSearch searchFilter={searchFilter}/>
                ) : (
                    <EventSearch searchFilter={searchFilter}/>
                )
            }
        </div>
    );
};

export default Search;