import React from "react";
import {useQuery} from "@tanstack/react-query";
import SearchMap from "components/Search/SearchMap";
import SearchResults from "components/Search/SearchResults";
import {FilterFormModel} from "components/Search/SearchFilter";
import EventModel from "api/model/EventModel";
import {searchEvents} from "api/actions/EventActions";
import EventList from "components/Search/EventSearch/EventList";
import EventMap from "components/Search/EventSearch/EventMap";

interface EventSearchProps {
    searchFilter?: FilterFormModel,
}

const EventSearch = (props: EventSearchProps) => {
    const {
        data,
        isLoading,
    } = useQuery<EventModel[]>({
        queryKey: ['events-search-query', props.searchFilter],
        queryFn: () => searchEvents(props.searchFilter),
    });
    return (
        <SearchResults
            isLoading={isLoading}
            list={<EventList events={data}/>}
            map={(
                <SearchMap>
                    {setMarkerInfo => <EventMap setMarkerInfo={setMarkerInfo} events={data}/>}
                </SearchMap>
            )}
        />
    )
}

export default EventSearch;