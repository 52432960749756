import React from 'react';
import urlSlug from 'url-slug'
import {Divider} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EventModel from "api/model/EventModel";
import ItemTag from "components/common/ItemTag";
import EventPrices from "components/Search/EventSearch/EventPrices";
import "./styles.css";
import {plainDateRangeToDisplayString} from "model/PlainDate";

interface EventListItemProps {
    event: EventModel,
}

const EventListItem = (props: EventListItemProps) => {
    const {t} = useTranslation();
    const {event} = props;
    return (
        <Link
            target="_blank"
            to={`/event/${event.id}/${urlSlug(`${event.location.town} ${event.name}`)}`}
            className="event-search-list-item"
        >
            <div className="event-search-list-item__image">
                <img alt={event.name} src={event.keyPhoto}/>
            </div>
            <div className="event-search-list-item__content">
                <div className="event-search-list-item__title">
                    {event.name}
                </div>
                <div className="event-search-list-item__dates">
                    {plainDateRangeToDisplayString(event.dateStart, event.dateEnd)}
                </div>
                <div className="event-search-list-item__description">
                    {event.descriptionShort}
                </div>
                <div className="event-search-list-items__tags-wrapper">
                    <Divider/>
                    <div className="event-search-list-item__location">
                        <PlaceIcon />{event.location.town}
                    </div>
                    <div className="event-search-list-item__tags">
                        {
                            event.allowedCategories?.map(cat => (
                                <ItemTag key={cat} size="M" type="Success">
                                {t(`product.category.${cat}`)}
                                </ItemTag>
                            ))
                        }
                    </div>
                    <EventPrices prices={event.prices}/>
                </div>
            </div>
        </Link>
    );
}

export default EventListItem;