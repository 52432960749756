import React, {useState} from 'react';
import {APIProvider, InfoWindow, Map} from "@vis.gl/react-google-maps";
import environment from "lib/EnvHelper";
import "./styles.css";

export interface MarkerInfo {
    marker: google.maps.marker.AdvancedMarkerElement,
    title: any,
    content: any,
}

interface SearchMapProps {
    children: (setMarkerInfo: (info: MarkerInfo | null) => void) => any,
}

const SearchMap = (props: SearchMapProps) => {
    const {
        children,
    } = props;
    const [markerInfo, setMarkerInfo] = useState<MarkerInfo | null>();
    return (
        <div className="search-map">
            <APIProvider apiKey={environment.googleMapsKey}>
                <Map
                    defaultZoom={15}
                    scrollwheel={true}
                    mapTypeControl={false}
                    streetViewControl={false}
                    fullscreenControl={false}
                    defaultCenter={{
                        lat: 59.436962,
                        lng: 24.753574,
                    }}
                    mapId="287a9dc34d2b207d"
                >
                    {children(setMarkerInfo)}
                    {
                        markerInfo && (
                            <InfoWindow
                                anchor={markerInfo.marker}
                                headerContent={markerInfo.title}
                            >
                                {markerInfo.content}
                            </InfoWindow>
                        )
                    }
                </Map>
            </APIProvider>
        </div>
    );
}

export default SearchMap;