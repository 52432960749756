import React from "react";
import urlSlug from "url-slug";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PopupModel from "api/model/PopupModel";
import PropertyModel from "api/model/PropertyModel";
import ItemTag from "components/common/ItemTag";
import PopupPriceTag from "components/Search/PopupSearch/PopupPriceTag";
import "./styles.css";

interface PopupInfoProps {
    popups: PopupModel[],
    property: PropertyModel,
}

const PopupMapInfo = (props: PopupInfoProps) => {
    const {t} = useTranslation();
    const {
        popups, property
    } = props;
    return (
        <div className="popup-info-container">
            {
                popups.map(popup => (
                    <div className="popup-info">
                        <Link
                            target="_blank"
                            to={`/popup/${popup.id}/${urlSlug(`${property.name} ${popup.name}`)}`}
                        >
                            {
                                popup.keyPhotoUrl && (
                                    <img
                                        className="popup-info__image"
                                        src={popup.keyPhotoUrl} alt={popup.name}
                                    />
                                )
                            }
                            <div className="popup-info__data">
                                <div className="popup-info__title">
                                    {popup.name}
                                    <PopupPriceTag
                                        isSmall
                                        prices={popup.prices}
                                    />
                                </div>
                                <div className="popup-info__description">
                                    {popup.descriptionShort}
                                </div>
                                <div className="popup-info__tags">
                                    {
                                        popup.requestedProductCategories?.map(cat => (
                                            <ItemTag size="S" type="Success">
                                                {t(`product.category.${cat}`)}
                                            </ItemTag>
                                        ))
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                ))
            }
        </div>
    )
}

export default PopupMapInfo;