import React from 'react';
import Hotjar from '@hotjar/browser';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from "@mui/material";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ToastContainer} from 'react-toastify';
import "react-image-gallery/styles/css/image-gallery.css";
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {muiTheme} from "theme";
import environment from "lib/EnvHelper";
import routes from "./routes";
import './index.css';
import './i18n';
import LocationWrapper from "components/common/LocationWrapper";

switch (environment.env) {
    case "live":
        ReactGA.initialize("G-27RSSB8VSK");
        Hotjar.init(5188921, 6);
        break;
    case "test":
        ReactGA.initialize("G-T2F02GW95F");
        break;
}

const queryClient = new QueryClient();

const router = createBrowserRouter(routes());

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LocationWrapper>
                <ThemeProvider theme={muiTheme}>
                    <RouterProvider router={router}/>
                    <ToastContainer hideProgressBar position={"top-right"}/>
                </ThemeProvider>
            </LocationWrapper>
        </QueryClientProvider>
    </React.StrictMode>
);

