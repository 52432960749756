import {Params} from "react-router-dom";
import {productCategories, ProductCategory} from "api/model/ProductCategory";
import {SearchType, searchTypesArray} from "model/SearchType";
import {plainDateFromISO, plainDateToISO} from "model/PlainDate";
import {FilterFormModel} from "components/Search/SearchFilter";

export const paramsToUri = (params?: FilterFormModel): string | undefined => {
    let queries: string[] = [];
    if (!params) {
        return;
    }
    if (params.searchText && params.searchText.length > 0) {
        queries.push(`search=${encodeURI(params.searchText)}`);
    }
    if (params.categories && params.categories.length > 0) {
        const filtered = params.categories.filter(cat => cat !== "ANY");
        if (filtered.length > 0) {
            queries.push(`categories=${filtered.join(",")}`);
        }
    }
    if (params.dates?.from) {
        queries.push(`dateFrom=${plainDateToISO(params.dates.from)}`);
    }
    if (params.dates?.to) {
        queries.push(`dateTo=${plainDateToISO(params.dates.to)}`);
    }
    if (queries.length > 0) {
        return queries.join("&");
    }
    return;
}

export const doesHaveFilters = (filter?: FilterFormModel) => {
    if (!filter) {
        return false;
    }
    if (filter.searchText && filter.searchText.length > 0) {
        return true;
    }
    if (filter.categories && filter.categories.length > 0) {
        return true;
    }
    if (filter.dates?.from || filter.dates?.to) {
        return true;
    }
    return false;
}

interface SearchURIParams {
    search?: string,
    categories?: string,
    dateFrom?: string,
    dateTo?: string,
}

export const uriToParams = (uriParams: SearchURIParams) => {
    const params: FilterFormModel = {
        searchText: uriParams.search,
        dates: {},
    };
    if (uriParams.categories && uriParams.categories.length > 0) {
        const split = uriParams.categories.split(",");
        const categories: ProductCategory[] = [];
        for (let catStr of split) {
            if (productCategories.includes(catStr as ProductCategory)) {
                categories.push(catStr as ProductCategory);
            }
        }
        params.categories = categories;
    }
    if (uriParams.dateFrom && uriParams.dateFrom.length > 0) {
        const dateFrom = plainDateFromISO(uriParams.dateFrom);
        if (dateFrom) {
            params.dates = {
                from: dateFrom
            };
        }
    }
    if (uriParams.dateTo && uriParams.dateTo.length > 0) {
        const dateTo = plainDateFromISO(uriParams.dateTo);
        if (dateTo) {
            params.dates = {
                ...params.dates,
                to: dateTo,
            };
        }
    }
    return params;
}

export const getSearchType = (params: Readonly<Params>): SearchType | undefined => {
    const {searchType} = params;
    if (searchType && searchTypesArray.includes(searchType as SearchType)) {
        return searchType as SearchType;
    }
}

export interface SearchFilterModel {
    searchType?: SearchType,
    formModel: FilterFormModel,
}

export const getSearchFilterModelFromParams = (searchParams: URLSearchParams, params: Readonly<Params>): SearchFilterModel => {
    const searchType = getSearchType(params);
    const search = searchParams.get("search") || undefined;
    const categories = searchParams.get("categories") || undefined;
    const dateFrom = searchParams.get("dateFrom") || undefined;
    const dateTo = searchParams.get("dateTo") || undefined;
    return {
        searchType,
        formModel: uriToParams({
            search,
            categories,
            dateFrom,
            dateTo,
        })
    };
}