import React, {useCallback, useEffect, useMemo} from "react";
import {v4 as uuidV4} from "uuid";
import {AdvancedMarker, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import PlaceIcon from "@mui/icons-material/Place";
import PopupModel from "api/model/PopupModel";
import PropertyModel from "api/model/PropertyModel";
import {PopupSearchCountsResponse} from "api/model/PopupSearchResponse";
import {MarkerInfo} from "components/Search/SearchMap";
import PopupMapInfo from "components/Search/PopupSearch/PopupMapInfo";
import {MarkerModel} from "components/Search/PopupSearch/PopupMapMarkerClusterer";
import "./styles.css";

interface PopupMarkerProps {
    showInfo: (markerInfo: MarkerInfo) => void,
    property: PropertyModel,
    propertyPopups: PopupModel[],
    popupCounts: PopupSearchCountsResponse,
    setRef: (ref: MarkerModel) => void,
    removeRef: (id: string) => void,
}

const PopupMapMarker = (props: PopupMarkerProps) => {
    const {
        setRef,
        propertyPopups,
        showInfo,
        property,
        removeRef,
        popupCounts,
    } = props;
    const [markerRef, marker] = useAdvancedMarkerRef();
    const id = useMemo(() => {
        return uuidV4();
    }, []);
    const handleMarkerClick = useCallback(() => marker && showInfo({
        marker,
        title: (
            <h2 className="popup-search__map--info-title">
                <PlaceIcon color="secondary" fontSize="medium"/>{property.name}
            </h2>
        ),
        content: (
            <PopupMapInfo
                property={property}
                popups={propertyPopups}
            />
        ),
    }), [marker]);
    useEffect(() => {
        if (marker) {
            setRef({
                id,
                marker,
                count: popupCounts[property.id] || 0
            });
            return () => removeRef(id);
        }
    }, [marker]);
    if ((popupCounts[property.id] || 0) < 1) {
        return <></>;
    }
    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={property.location}
                title={property.name}
                onClick={handleMarkerClick}
            >
                <div className="popup-search__marker">
                    {popupCounts[property.id] || 0}
                </div>
            </AdvancedMarker>
        </>
    )
}

export default PopupMapMarker;
