import React from "react";
import {useTranslation} from "react-i18next";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import "./styles.css";

const PopupMapComingSoonInfo = () => {
    const {t} = useTranslation();
    return (
        <div className="popup-info-coming-soon">
            <AddBusinessIcon fontSize="large" color="primary" />
            {t('popup.search.map.coming.soon.info')}
        </div>
    )
}

export default PopupMapComingSoonInfo;