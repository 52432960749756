export const formatPrice = (price?: number | string | any): string => {
    if (price) {
        if (typeof price === "number") {
            if (price % 1 === 0) {
                return price.toFixed(0);
            } else {
                return price.toFixed(2);
            }
        } else if (typeof price === "string") {
            const float = parseFloat(price);
            return formatPrice(float);
        }
    }
    return "0";
}
