import React, {useEffect, useState} from 'react';
import {DateTime} from "luxon";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, Select, TextField} from '@mui/material';
import {isEmailValid} from "lib/EmailHelper";
import useScreenSize from "lib/useScreenSize";
import PlainDate, {comparePlainDate} from "model/PlainDate";
import FileModel from "api/model/FileModel";
import {productCategories, ProductCategory} from "api/model/ProductCategory";
import FileUpload from "components/common/Form/FileUpload";
import DateRangePicker, {DateRangeModel} from "components/common/DateRangePicker";
import "./styles.css";

export interface ApplicationFormModel {
    contactEmail: string,
    contactPhone: string,
    companyName: string,
    brandName: string,
    offerDescription: string,
    files: FileModel[],
    categories: ProductCategory[],
    dates: {
        from: PlainDate,
        to: PlainDate,
    },
    isNewsLetterSignUpAllowed: boolean,
}

interface ApplicationFormProps {
    apply: (form: ApplicationFormModel) => void,
    isLoading: boolean,
    hideDates?: boolean,
    clearFields?: boolean,
    disabledDates?: DateRangeModel[],
    availableDates?: DateRangeModel[],
    allowApplyingFrom?: PlainDate,
}

const currentDateTime = DateTime.now();
const currentDate: PlainDate = {
    day: currentDateTime.day,
    month: currentDateTime.month,
    year: currentDateTime.year,
};

const ApplicationForm = (props: ApplicationFormProps) => {
    const {t} = useTranslation();
    const screenSize = useScreenSize();
    const isMobile = screenSize.width <= 600;
    const {
        apply,
        isLoading,
        hideDates,
        clearFields,
        allowApplyingFrom,
        disabledDates = [],
        availableDates = [],
    } = props;
    const {
        reset,
        control,
        handleSubmit,
    } = useForm<ApplicationFormModel>({
        defaultValues: {
            categories: [],
            files: [],
            isNewsLetterSignUpAllowed: true,
        }
    });
    useEffect(() => {
        if (clearFields) {
            reset({
                files: [],
                brandName: '',
                companyName: '',
                contactPhone: '',
                categories: [],
                offerDescription: '',
                contactEmail: '',
                dates: {}
            });
        }
    }, [clearFields, reset]);
    const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState<string | undefined>();
    const onSubmit: SubmitHandler<ApplicationFormModel> = (data) => {
        if (!isLoading) {
            data.contactEmail = data.contactEmail.trim();
            apply(data);
        }
    }
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="application-form"
        >
            <div className="application-form__wrapper">
                {
                    !hideDates && (
                        <div className="application-form__section">
                            <Controller
                                name="dates"
                                rules={{
                                    validate: value => {
                                        if (!value) {
                                            setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.invalid'));
                                            return false;
                                        }
                                        const {from, to} = value;
                                        if (!from || !to) {
                                            setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.invalid'));
                                            return false;
                                        }
                                        if (comparePlainDate(from, to) > 0) {
                                            setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.invalid'));
                                            return false;
                                        }
                                        if (comparePlainDate(from, currentDate) < 0) {
                                            setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.past'));
                                            return false;
                                        }

                                        for (const availableDate of availableDates) {
                                            if (comparePlainDate(from, availableDate.from) < 0 || comparePlainDate(from, availableDate.to) > 0) {
                                                setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.has.blocked'));
                                                return false;
                                            }
                                            if (comparePlainDate(to, availableDate.from) < 0 || comparePlainDate(to, availableDate.to) > 0) {
                                                setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.has.blocked'));
                                                return false;
                                            }
                                        }

                                        for (const booked of disabledDates) {
                                            if (comparePlainDate(from, booked.from) >= 0 && comparePlainDate(from, booked.to) <= 0) {
                                                setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.has.blocked'));
                                                return false;
                                            }
                                            if (comparePlainDate(to, booked.from) >= 0 && comparePlainDate(to, booked.to) <= 0) {
                                                setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.has.blocked'));
                                                return false;
                                            }
                                            if (comparePlainDate(from, booked.from) <= 0 && comparePlainDate(to, booked.to) >= 0) {
                                                setDateRangeErrorMessage(t('popup.details.page.application.form.error.dates.has.blocked'));
                                                return false;
                                            }
                                        }
                                        return true;
                                    }
                                }}
                                control={control}
                                render={({field, fieldState}) => (
                                    <>
                                        <div className="application-form__label">
                                            {t('popup.details.page.application.dates.label')}
                                        </div>
                                        <DateRangePicker
                                            staticCalendar
                                            toDate={field.value?.to}
                                            fromDate={field.value?.from}
                                            blockedDates={disabledDates}
                                            availableDates={availableDates}
                                            enableDatesFrom={allowApplyingFrom}
                                            errorMessage={dateRangeErrorMessage}
                                            isError={!!fieldState.error || fieldState.invalid || false}
                                            apply={(fromDate, toDate) =>
                                                field.onChange({
                                                    from: fromDate,
                                                    to: toDate,
                                                })
                                            }
                                        />
                                    </>
                                )}
                            />
                        </div>
                    )
                }
                <div className="application-form__section">
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                            maxLength: 100,
                            validate: value => isEmailValid(value),
                        }}
                        control={control}
                        name="contactEmail"
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.contact.email.label')}
                                </div>
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    placeholder={t('popup.details.page.application.contact.email.placeholder')}
                                />
                            </>
                        )}
                    />
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                            maxLength: 100,
                        }}
                        control={control}
                        name="contactPhone"
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.contact.phone.label')}
                                </div>
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    placeholder={t('popup.details.page.application.contact.phone.placeholder')}
                                />
                            </>
                        )}
                    />
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                            maxLength: 100,
                        }}
                        control={control}
                        name="companyName"
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.company.name.label')}
                                </div>
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    placeholder={t('popup.details.page.application.company.name.placeholder')}
                                />
                            </>
                        )}
                    />
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                            maxLength: 100,
                        }}
                        control={control}
                        name="brandName"
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.brand.name.label')}
                                </div>
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    placeholder={t('popup.details.page.application.brand.name.placeholder')}
                                />
                            </>
                        )}
                    />
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                        }}
                        name="categories"
                        control={control}
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.categories.label')}
                                </div>
                                <Select
                                    {...field}
                                    fullWidth
                                    multiple
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <span>Placeholder</span>;
                                        }
                                        return (
                                            <span className="application-form__categories-value">
                                                {
                                                    selected.map(cat => t(`product.category.${cat}`)).join(', ')
                                                }
                                            </span>
                                        );
                                    }}
                                    autoWidth={isMobile}
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    onChange={(e) => field.onChange(e)}
                                >
                                    {
                                        productCategories.map(category => (
                                            <MenuItem className="application-form__categories-menu-item" key={category}
                                                      value={category}>
                                                {t(`product.category.${category}`)}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </>
                        )}
                    />
                    <Controller
                        rules={{
                            required: t('form.field.error.required'),
                            maxLength: 1000,
                        }}
                        control={control}
                        name="offerDescription"
                        render={({field, fieldState}) => (
                            <>
                                <div className="application-form__label">
                                    {t('popup.details.page.application.offer.description.label')}
                                </div>
                                <TextField
                                    {...field}
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    error={!!fieldState.error || fieldState.invalid || false}
                                    placeholder={t('popup.details.page.application.offer.description.placeholder')}
                                />
                            </>
                        )}
                    />
                    <div className="application-form__label">
                        {t('popup.details.page.application.images.label')}
                    </div>
                    <div className="application-form__label-meta">
                        {t('popup.details.page.application.images.label.meta')}
                    </div>
                    <Controller
                        rules={{
                            validate: value => value && value.length > 0 && value.length <= 10
                        }}
                        name="files"
                        control={control}
                        render={({field, fieldState}) => (
                            <FileUpload
                                isError={!!fieldState.error || fieldState.invalid || false}
                                value={field.value}
                                onChange={(files) => field.onChange(files)}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="application-form__news-letter-sign-up">
                <Controller
                    name="isNewsLetterSignUpAllowed"
                    control={control}
                    render={({field}) => (
                        <FormControlLabel
                            labelPlacement="end"
                            {...field}
                            checked={field.value}
                            control={
                                <Checkbox size="medium"/>
                            }
                            label={
                                <span className="application-form__news-letter-sign-up-text">
                                    {t('popup.details.page.application.join.news.letter')}
                                </span>
                            }
                        />
                    )}
                />
                <p>
                    {t('popup.details.page.application.join.news.letter.subtitle')}
                </p>
            </div>
            <Button
                fullWidth
                type="submit"
                variant="contained"
                className="application-form__button"
            >
                <PostAddIcon/>&nbsp;{t('popup.details.page.application.apply.button')}{
                isLoading && (<>&nbsp;<CircularProgress size="20px" color="warning"/></>)
            }
            </Button>
        </form>
    );
}

export default ApplicationForm;