import {createRoutesFromElements, Route} from "react-router-dom";
import MainPage from "pages/MainPage";
import PopupDetailsPage from "pages/PopupDetailsPage";
import SearchPage from "pages/SearchPage";
import NewsLetterSignupPage from "pages/NewsLetterSignupPage";
import EventDetailsPage from "pages/EventDetailsPage";

const Routes = () => createRoutesFromElements(
    <>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/search" element={<SearchPage searchType="popups"/>}/>
        <Route path="/search/:searchType" element={<SearchPage/>}/>
        <Route path="/popup/:popupId/:slug" element={<PopupDetailsPage/>}/>
        <Route path="/event/:eventId/:slug" element={<EventDetailsPage/>}/>
        <Route path="/join-us" element={<NewsLetterSignupPage/>}/>
        <Route path="*" element={<MainPage/>}/>
    </>
);

export default Routes;