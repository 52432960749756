import React, {useEffect, useRef, useState} from 'react';
import {Button, Divider} from "@mui/material";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReactImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
import EventModel from "api/model/EventModel";
import EventDetailsHeader from "components/EventDetails/EventDetailsHeader";
import EventDetailsPrices from "components/EventDetails/EventDetailsPrices";
import EventDetailsExtras from "components/EventDetails/EventDetailsExtras";
import EventDetailsMap from "components/EventDetails/EventDetailsMap";
import EventDetailsCategories from "components/EventDetails/EventDetailsCategories";
import EventDetailsApplication from "components/EventDetails/EventDetailsApplication";
import {useTranslation} from "react-i18next";
import "./styles.css";

export interface EventDetailsProps {
    event: EventModel,
}

const generateImageObjects = (event: EventModel): ReactImageGalleryItem[] => {
    const response: ReactImageGalleryItem[] = [];
    if (event.keyPhoto) {
        response.push({
            original: event.keyPhoto,
            originalClass: 'event-details-gallery__image'
        });
    }
    event.photoUrls?.forEach((photo) => {
        response.push({
            original: photo,
            originalClass: 'event-details-gallery__image'
        });
    });
    return response;
}

const EventDetails = (props: EventDetailsProps) => {
    const {t} = useTranslation();
    const applicationsRef = useRef<HTMLDivElement>(null);
    const [isApplicantsVisible, setIsApplicantsVisible] = useState(false);
    const {event} = props;
    useEffect(() => {
        if (applicationsRef.current) {
            const observer = new IntersectionObserver(function (entries) {
                if (entries[0].isIntersecting) {
                    setIsApplicantsVisible(true);
                }
                if (!entries[0].isIntersecting) {
                    setIsApplicantsVisible(false);
                }
            }, {threshold: [0]});
            observer.observe(applicationsRef.current);
            return () => observer.disconnect();
        }
    }, [applicationsRef.current]);
    const scrollToApplications = () => {
        applicationsRef.current?.scrollIntoView({behavior: "smooth"});
    }
    return (
        <div className="event-details-wrapper">
            <div className="event-details-container">
                <div className="event-details-gallery">
                    <ReactImageGallery
                        showNav={true}
                        showBullets={true}
                        showPlayButton={false}
                        items={generateImageObjects(event)}
                    />
                </div>
                <div className="event-details">
                    <EventDetailsHeader event={event}/>
                    <EventDetailsPrices prices={event.prices}/>
                    <Divider/>
                    <p className="event-details__description" dangerouslySetInnerHTML={{__html: event.description}}/>
                    <EventDetailsCategories event={event}/>
                    <EventDetailsExtras event={event}/>
                    <EventDetailsMap event={event}/>
                    <div className="event-details__apply-cta">
                        {
                            !isApplicantsVisible ? (
                                <Button variant="contained" color="success" size="large" onClick={scrollToApplications}>
                                    <PostAddIcon/>&nbsp;{t('event.details.page.application.scroll.to.apply.button')}
                                </Button>
                            ) : (
                                <span>
                                <PostAddIcon/>&nbsp;{t('event.details.page.application.scroll.to.apply.button')}
                            </span>
                            )
                        }
                    </div>
                    <div ref={applicationsRef}>
                        <EventDetailsApplication event={event}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetails;