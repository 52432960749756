import React from "react";
import PageWithHeader from "components/common/PageWithHeader";
import HeroSection from "components/HeroSection";
import Search from "components/Search";

const MainPage = () => {
    return (
        <PageWithHeader searchType="popups">
            <HeroSection/>
            <Search searchType="popups"/>
        </PageWithHeader>
    );
};

export default MainPage;
