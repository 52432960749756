import React from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PropertyModel from "api/model/PropertyModel";
import './styles.css';
import {useTranslation} from "react-i18next";

interface ComingSoonItemProps {
    property: PropertyModel,
    index: number,
}

const getPhotoByIndex = (index: number) => {
    if (index % 3 === 0) {
        return '/static/images/coming_soon_3.jpg';
    }
    if (index % 2 === 0) {
        return '/static/images/coming_soon_2.jpg';
    }
    return '/static/images/coming_soon_1.jpg';
}

const ComingSoonItem = (props: ComingSoonItemProps) => {
    const {t} = useTranslation();
    const {property, index} = props;
    return (
        <div className="popup-search-list-coming-soon-item">
            <h2 className="popup-search-list-coming-soon-item__property">
                <AddLocationAltIcon color="primary"/>
                {property.name}
            </h2>
            <div className="popup-search-list-coming-soon-item__image">
                <img alt={property.name} src={getPhotoByIndex(index)}/>
            </div>
            <div className="popup-search-list-coming-soon-item__text">
                <AddBusinessIcon fontSize="large" color="primary" />
                {t('popup.search.list.coming.soon')}
            </div>
        </div>
    );
}

export default ComingSoonItem;