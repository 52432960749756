import React from "react";
import {useTranslation} from "react-i18next";
import {PopupPriceModel} from "api/model/PopupModel";
import ItemTag from "components/common/ItemTag";
import {formatPrice} from "lib/PriceHelper";

interface PopupPriceTagProps {
    prices: PopupPriceModel[],
    isSmall?: boolean,
}

const PopupPriceTag = (props: PopupPriceTagProps) => {
    const {t} = useTranslation();
    const {prices, isSmall} = props;
    const dayPrice: PopupPriceModel | undefined = prices.find(price => price.priceType === "PER_DAY");
    const weekPrice: PopupPriceModel | undefined = prices.find(price => price.priceType === "PER_WEEK");
    const monthPrice: PopupPriceModel | undefined = prices.find(price => price.priceType === "PER_MONTH");
    const fixedPrice: PopupPriceModel | undefined = prices.find(price => price.priceType === "FIXED");
    const price = dayPrice || weekPrice || monthPrice || fixedPrice;
    if (!price) {
        return <></>;
    }
    let translationKey: string | undefined;
    switch (price.priceType) {
        case "PER_WEEK":
            translationKey = 'popup.search.list.price.week.short';
            break;
        case "PER_MONTH":
            translationKey = 'popup.search.list.price.month.short';
            break;
        case "PER_DAY":
            translationKey = 'popup.search.list.price.day.short';
            break;
    }
    return (
        <ItemTag size={isSmall ? "S" : "M"}>
            {price.currency}{formatPrice(price.price)}{translationKey && '/' + t(translationKey)}
        </ItemTag>
    );
}

export default PopupPriceTag;