import React, {useEffect, useState} from "react";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useMutation} from "@tanstack/react-query";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {Autocomplete, Box, Button, CircularProgress, TextField} from "@mui/material";
import {isEmailValid} from "lib/EmailHelper";
import {CountryInterface, getCountriesByLanguage, getCountryByLanguageIso} from "lib/LocationsHelper";
import {handleRequestError} from "api/helper/ErrorHelper";
import {signupForNewsLetter} from "api/actions/NewsLetterActions";
import {SupportedLanguage} from "consts/LanguageConsts";
import "./styles.css";

interface SignupFormModel {
    email: string,
    country: CountryInterface | null
}

const SignupForm = () => {
    const {t, i18n} = useTranslation();
    const {
        reset,
        control,
        handleSubmit,
        setValue,
        getValues,
    } = useForm<SignupFormModel>({
        defaultValues: {
            email: '',
        }
    });
    const countries = getCountriesByLanguage(i18n.language as SupportedLanguage);
    const [clearCountryAfterSuccess, setClearCountryAfterSuccess] = useState(true);
    useEffect(() => {
        const country = getCountryByLanguageIso(i18n.language as SupportedLanguage);
        if (country) {
            setValue("country", country);
            setClearCountryAfterSuccess(false);
        }
    }, []);
    const {
        mutate,
        isPending
    } = useMutation({
        mutationFn: (request: SignupFormModel) => signupForNewsLetter({
            email: request.email,
            countryIso: request.country!.iso,
        }),
        onError: (axiosError: AxiosError) => handleRequestError(axiosError, error => {
            if (error) {
                toast(t(`common.errors.${error.type}`), {type: "error"});
            }
        }),
        onSuccess: () => {
            const countryValue = getValues("country") as CountryInterface | null;
            reset({
                email: '',
                country: clearCountryAfterSuccess ? null : countryValue
            });
            toast(t('news.letter.page.form.sign.up.toast.success'), {type: "success"});
        },
    });
    const onSubmit: SubmitHandler<SignupFormModel> = data => {
        if (!isPending) {
            mutate(data);
        }
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="news-letter-signup-form"
        >
            <Controller
                rules={{
                    validate: value => isEmailValid(value)
                }}
                control={control}
                name="email"
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error || fieldState.invalid || false}
                        label={t('news.letter.page.form.email.label')}
                    />
                )}
            />
            <Controller
                rules={{
                    required: t('form.field.error.required'),
                }}
                name="country"
                control={control}
                render={({field, fieldState}) => {
                    return (
                        <Autocomplete
                            id="signup-country-select"
                            className="vendors-signup-form__form-field"
                            options={countries}
                            fullWidth
                            autoHighlight
                            value={field.value || null}
                            inputValue={field.value?.iso}
                            getOptionLabel={(option) => option?.label || ''}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                    {...props}
                                >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        name={`${new Date().getTime()}`}
                                        label={t('news.letter.page.form.country.label')}
                                        error={!!fieldState.error || fieldState.invalid}
                                        slotProps={{
                                            htmlInput: {
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            },
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
            <Button
                fullWidth
                color="secondary"
                variant="contained"
                type="submit">
                {t('news.letter.page.form.sign.up.button.label')}
                {
                    isPending && (
                        <>
                            &nbsp;&nbsp;<CircularProgress size="20px" color="warning"/>
                        </>
                    )
                }
            </Button>
        </form>
    );
};

export default SignupForm;
