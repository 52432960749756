import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from "locales/en.json";
import et from "locales/et.json";
import lv from "locales/lv.json";

const resources = {
    en: {
        translation: en,
    },
    et: {
        translation: et,
    },
    lv: {
        translation: lv,
    },
};


i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources
    });