import React from 'react';
import {AxiosError} from "axios";
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {useMutation} from "@tanstack/react-query";
import {handleRequestError} from "api/helper/ErrorHelper";
import {ErrorType} from "api/model/ErrorType";
import {applyEvent} from "api/actions/ApplicationActions";
import ApplicationForm, {ApplicationFormModel} from "components/common/ApplicationForm";
import EventModel from "api/model/EventModel";
import {useLocationStore} from "store/locationStore";
import ApplicationRequest from "api/model/ApplicationRequest";
import {SupportedLanguage} from "consts/LanguageConsts";
import "./styles.css";

interface EventDetailsApplicationProps {
    event: EventModel
}

const knownRequestErrors: ErrorType[] = ["INVALID_PARAMETERS", "RESOURCE_NOT_FOUND"];

const EventDetailsApplication = (props: EventDetailsApplicationProps) => {
    const countryIso = useLocationStore(state => state.location?.countryIso) || 'EE';
    const {t, i18n} = useTranslation();
    const {event} = props;
    const {
        mutate,
        isPending,
        isSuccess,
    } = useMutation({
        mutationFn: (data: ApplicationFormModel) => {
            const request: ApplicationRequest = {
                ...data,
                languageIso: i18n.language as SupportedLanguage,
                files: data.files.filter(file => file.uuid).map(file => file.uuid || ''),
                countryIso,
            };
            if (event.allowDateSelection) {
                request.dates = {
                    from: data.dates.from,
                    to: data.dates.to,
                };
            }
            return applyEvent(event.id, request);
        },
        onError: (axiosError: AxiosError) => handleRequestError(axiosError, error => {
            if (error) {
                if (knownRequestErrors.includes(error.type)) {
                    toast(t(`popup.details.page.application.apply.error.${error.type}`), {type: "error"});
                } else {
                    toast(t(`common.errors.${error.type}`), {type: "error"});
                }
            }
        }),
        onSuccess: () => {
            toast(t('popup.details.page.application.apply.success'), {type: "success"});
        },
    });

    const apply = (data: ApplicationFormModel) => {
        mutate(data);
    };

    return (
        <ApplicationForm
            apply={apply}
            isLoading={isPending}
            clearFields={isSuccess}
            hideDates={!event.allowDateSelection}
            availableDates={[{
                from: event.dateStart,
                to: event.dateEnd,
            }]}
        />
    );
}

export default EventDetailsApplication;