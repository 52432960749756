import React, {useEffect, useRef, useState} from "react";
import cx from 'classnames';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import {productCategories} from "api/model/ProductCategory";
import PlainDate from "model/PlainDate";
import {SearchType} from "model/SearchType";
import DateRangePicker from "components/common/DateRangePicker";
import {paramsToUri} from "lib/SearchHelper";
import "./styles.css";

const filterCategoriesList = ['ANY', ...productCategories] as const;

type FilterCategories = typeof filterCategoriesList[number];

export interface FilterFormModel {
    searchText?: string,
    categories?: FilterCategories[],
    dates?: {
        from?: PlainDate,
        to?: PlainDate,
    },
}

interface SearchFilterProps {
    searchFilter?: FilterFormModel,
    searchType?: SearchType,
}

const isVisible = (rect: DOMRect) => {
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

const SearchFilter = (props: SearchFilterProps) => {
    const {searchFilter, searchType = "popups"} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const {
        control,
        handleSubmit,
        setValue
    } = useForm<FilterFormModel>({
        defaultValues: {
            categories: ["ANY"]
        }
    });
    const [isOpen, setIsOpen] = useState(false);
    window.addEventListener("scroll", () => {
        if (searchInputRef.current && !isVisible(searchInputRef.current.getBoundingClientRect())) {
            setIsOpen(false);
            searchInputRef.current.blur();
        }
    });
    useEffect(() => {
        if (searchFilter) {
            const {
                dates,
                searchText,
                categories,
            } = searchFilter;
            if (searchText && searchText.length > 0) {
                setValue('searchText', searchFilter.searchText);
            }
            if (categories && categories.length > 0) {
                setValue('categories', searchFilter.categories);
            }
            if (dates && dates.from && dates.to) {
                setValue("dates", searchFilter.dates);
            }
        }
    }, [searchFilter]);
    const onSubmit: SubmitHandler<FilterFormModel> = (data) => {
        let target = `/search/${searchType}`;
        const uriParams = paramsToUri(data);
        if (uriParams) {
            target = `${target}?${uriParams}`;
        }
        navigate(target);
        setIsOpen(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    }

    const filterClasses = cx({
        'search-filter': true,
        'search-filter--open': isOpen,
    });

    return (
        <div className="search-filter-wrapper">
            <form className={filterClasses} onSubmit={handleSubmit(onSubmit)}>
                <div className="search-filter__item">
                    <Controller
                        name="searchText"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                fullWidth
                                autoComplete="off"
                                size="medium"
                                inputRef={searchInputRef}
                                onFocus={() => setIsOpen(true)}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        )
                                    }
                                }}
                                placeholder={t('popup.search.filter.search.placeholder')}
                            />
                        )}
                    />
                </div>
                <div className="search-filter__item">
                    <Controller
                        name="dates"
                        control={control}
                        render={({field}) => (
                            <DateRangePicker
                                fromDate={field.value?.from}
                                toDate={field.value?.to}
                                apply={(fromDate, toDate) =>
                                    field.onChange({
                                        from: fromDate,
                                        to: toDate,
                                    })
                                }
                            />
                        )}
                    />
                </div>
                <div className="search-filter__item search-filter__item--small">
                    <Controller
                        name="categories"
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <Select
                                {...field}
                                fullWidth
                                multiple
                                size="small"
                                onChange={(e) => {
                                    let selectedCategories = e.target.value as FilterCategories[];
                                    if (selectedCategories.length < 1) {
                                        selectedCategories.push("ANY");
                                    } else if (selectedCategories[selectedCategories.length - 1] === "ANY") {
                                        selectedCategories = selectedCategories.filter(cat => cat === "ANY");
                                    } else if (selectedCategories.length > 1 && selectedCategories.includes("ANY")) {
                                        selectedCategories = selectedCategories.filter((cat) => cat !== "ANY");
                                    }
                                    e.target.value = selectedCategories;
                                    field.onChange(e);
                                }}
                            >
                                {
                                    filterCategoriesList.map(category => (
                                        <MenuItem key={category} value={category}>
                                            {
                                                category === "ANY" ? t('popup.search.filter.product.category.ANY') : t(`product.category.${category}`)
                                            }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        )}
                    />
                </div>
                <div className="search-filter__item search-filter__item--button">
                    <Button type="submit" fullWidth variant="outlined">
                        {t('popup.search.filter.search.button')}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default SearchFilter;