import React from 'react';
import {useTranslation} from "react-i18next";
import "./styles.css";
import {theme} from "theme";

const HeroSection = () => {
    const {t} = useTranslation();
    return (
        <div className="hero-section">
            <div className="hero-section-text">
                <h1 style={{color: theme.color.secondary}}>
                    {t('popup.main.page.hero.section.title')}
                </h1>
                <h3 style={{color: theme.color.secondary}}>
                    {t('popup.main.page.hero.section.subtitle')}
                </h3>
            </div>
            {/*<img alt="provente" src="/static/images/banner.png" />*/}
        </div>
    );
}

export default HeroSection;