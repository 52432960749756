import React from 'react';
import {useTranslation} from "react-i18next";
import {formatPrice} from "lib/PriceHelper";
import ItemTag from "components/common/ItemTag";
import {EventPriceModel} from "api/model/EventModel";
import 'components/Search/EventSearch/EventPrices/styles.css';

interface EventPricesProps {
    prices: EventPriceModel[]
}

const EventPrices = (props: EventPricesProps) => {
    const {t} = useTranslation();
    const {prices} = props;
    let day: EventPriceModel | undefined;
    let week: EventPriceModel | undefined;
    let month: EventPriceModel | undefined;
    let fixed: EventPriceModel | undefined;
    for (const price of prices) {
        switch (price.priceType) {
            case "PER_DAY":
                day = price;
                break;
            case "PER_WEEK":
                week = price;
                break;
            case "PER_MONTH":
                month = price;
                break;
            case "FIXED":
                fixed = price;
                break;
        }
    }
    return (
        <div className="event-search-prices">
            {day && (
                <div className="event-search-prices-item">
                    <ItemTag size="L">
                        {day.currency}{formatPrice(day.price)}/{t('popup.search.list.price.day.short')}
                    </ItemTag>
                </div>
            )}
            {week && (
                <div className="event-search-prices-item">
                    <ItemTag size="L">
                        {week.currency}{formatPrice(week.price)}/{t('popup.search.list.price.week.short')}
                    </ItemTag>
                </div>
            )}
            {month && (
                <div className="event-search-prices-item">
                    <ItemTag size="L">
                        {month.currency}{formatPrice(month.price)}/{t('popup.search.list.price.month.short')}
                    </ItemTag>
                </div>
            )}
            {fixed && (
                <div className="event-search-prices-item">
                    <ItemTag size="L">
                        {fixed.currency}{formatPrice(fixed.price)}
                    </ItemTag>
                </div>
            )}
        </div>
    );
}

export default EventPrices;
