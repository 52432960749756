import React, {useEffect, useState} from "react";
import {DateTime} from 'luxon';
import cx from 'classnames';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useTranslation} from "react-i18next";
import CalendarGrid from "components/common/DateRangePicker/CalendarGrid";
import PlainDate, {comparePlainDate} from "model/PlainDate";
import {Button, Popover, TextField} from "@mui/material";
import {TFunction} from "i18next";
import "./styles.css";

const monthsToTranslations = {
    1: 'calendar.january',
    2: 'calendar.february',
    3: 'calendar.march',
    4: 'calendar.april',
    5: 'calendar.may',
    6: 'calendar.june',
    7: 'calendar.july',
    8: 'calendar.august',
    9: 'calendar.september',
    10: 'calendar.october',
    11: 'calendar.november',
    12: 'calendar.december',
} as {
    [month: number]: string
};

export interface DateRangeModel {
    from: PlainDate,
    to: PlainDate,
}

interface DateRangePickerProps {
    fromDate?: PlainDate,
    toDate?: PlainDate,
    apply: (fromDate?: PlainDate, toDate?: PlainDate) => void,
    staticCalendar?: boolean,
    blockedDates?: DateRangeModel[],
    availableDates?: DateRangeModel[],
    enableDatesFrom?: PlainDate,
    isError?: boolean,
    errorMessage?: string,
}

const formatPlainDate = (t: TFunction, date?: PlainDate) => {
    if (!date) {
        return t('date.range.selector.placeholder');
        // return `${t('date.range.selector.placeholder.day')}.${t('date.range.selector.placeholder.month')}.${t('date.range.selector.placeholder.year')}`;
    }
    const day = date.day > 9 ? `${date.day}` : `0${date.day}`;
    const month = date.month > 9 ? `${date.month}` : `0${date.month}`;
    const yearString = `${date.year}`;
    const yearSmall = yearString.slice(2, 4);
    return `${day}.${month}.${yearSmall}`;
}

const doesMonthHaveAvailableDays = (
    month: number,
    year: number,
    blockedDates: DateRangeModel[] = [],
    availableDates: DateRangeModel[] = [],
    enableDatesFrom?: PlainDate
) => {
    const selectedDate = DateTime.fromObject({
        day: 1,
        month,
        year,
    });
    const daysInMonth = selectedDate.daysInMonth || 31;
    for (let day = 1; day <= daysInMonth; day++) {
        const plainDate: PlainDate = {day, month, year};
        let isDateAvailable = true;
        if (enableDatesFrom && comparePlainDate(enableDatesFrom, plainDate) > 0) {
            isDateAvailable = false;
        }
        if (availableDates.length > 0) {
            for (const availableDateRange of availableDates) {
                if (comparePlainDate(plainDate, availableDateRange.from) < 0 || comparePlainDate(plainDate, availableDateRange.to) > 0) {
                    isDateAvailable = false;
                }
            }
        }
        if (blockedDates.length > 0) {
            for (const blockedDateRange of blockedDates) {
                if (comparePlainDate(plainDate, blockedDateRange.from) >= 0 && comparePlainDate(plainDate, blockedDateRange.to) <= 0) {
                    isDateAvailable = false;
                }
            }
        }
        if (isDateAvailable) {
            return true;
        }
    }
    return false;
}

const DateRangePicker = (props: DateRangePickerProps) => {
    const {
        fromDate,
        toDate,
        apply,
        staticCalendar,
        blockedDates,
        isError,
        errorMessage,
        availableDates,
        enableDatesFrom,
    } = props;
    const {t} = useTranslation();

    const currentDate = DateTime.now();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        event.currentTarget.focus();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);

    const [viewYear, setViewYear] = useState(fromDate?.year || currentDate.year);
    const [viewMonth, setViewMonth] = useState(fromDate?.month || currentDate.month);

    useEffect(() => {
        if (!fromDate?.month) {
            let currentDate = DateTime.now();
            let availableMonth: number | undefined;
            let availableYear: number | undefined;
            let loops = 0;
            do {
                if (doesMonthHaveAvailableDays(currentDate.month, currentDate.year, blockedDates, availableDates, enableDatesFrom)) {
                    availableMonth = currentDate.month;
                    availableYear = currentDate.year;
                } else {
                    currentDate = currentDate.plus({
                        month: 1
                    });
                }
                loops++;
            } while (!availableMonth && loops < 12);
            if (availableMonth && availableYear) {
                setViewMonth(availableMonth);
                setViewYear(availableYear);
            }
        }
    }, [fromDate, blockedDates, availableDates, enableDatesFrom]);

    // TODO set view month and year based on what is available.

    const changeViewYear = (add: boolean) => {
        if (add) {
            if (viewMonth === 12) {
                setViewMonth(1);
                setViewYear(viewYear + 1);
            } else {
                setViewMonth(viewMonth + 1);
            }
        } else {
            if (viewMonth === 1) {
                setViewMonth(12);
                setViewYear(viewYear - 1);
            } else {
                setViewMonth(viewMonth - 1);
            }
        }
    }

    const [from, setFrom] = useState<PlainDate | undefined>(fromDate);
    const [to, setTo] = useState<PlainDate | undefined>(toDate);

    useEffect(() => {
        setFrom(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setTo(toDate);
    }, [toDate]);

    const selectDate = (newDate: PlainDate) => {
        if (from && !to && comparePlainDate(from, newDate) <= 0) {
            apply(from, newDate);
        } else {
            apply(newDate);
        }
    };

    const selectToday = () => {
        setViewMonth(currentDate.month);
        setViewYear(currentDate.year);
    }

    const triggerClasses = cx({
        'date-range-picker-trigger': true,
        'date-range-picker-trigger--open': isOpen,
        'date-range-picker-trigger--static': staticCalendar,
    });

    const footerClasses = cx({
        'date-range-picker-footer': true,
        'date-range-picker-footer--static': staticCalendar
    });

    const popupClasses = cx({
        'date-range-picker-popup': true,
        'date-range-picker-popup--static': staticCalendar,
        'date-range-picker-popup--error': isError
    });

    const calendar = (
        <div className={popupClasses}>
            <div className="date-range-picker-calendar">
                <div className="date-range-picker-calendar__month">
                    <Button onClick={() => changeViewYear(false)} variant="text">
                        <ChevronLeftIcon/>
                    </Button>
                    {t(monthsToTranslations[viewMonth])} {viewYear}
                    <Button onClick={() => changeViewYear(true)} variant="text">
                        <ChevronRightIcon/>
                    </Button>
                </div>
                <CalendarGrid
                    toDate={to}
                    fromDate={from}
                    viewYear={viewYear}
                    viewMonth={viewMonth}
                    selectDate={selectDate}
                    blockedDates={blockedDates}
                    availableDates={availableDates}
                    enableDatesFrom={enableDatesFrom}
                />
            </div>
            <div className={footerClasses}>
                <Button color="secondary" variant="outlined" onClick={selectToday}>
                    {t('date.range.selector.today')}
                </Button>
                {!staticCalendar && (
                    <Button variant="contained" onClick={handleClose}>
                        {t('date.range.selector.ok')}
                    </Button>
                )}
            </div>
        </div>
    );

    const wrapperClasses = cx({
        'date-range-picker': true,
        'date-range-picker--static': staticCalendar,
        'date-range-picker--error': isError
    });

    return (
        <div className={wrapperClasses}>
            <div onClick={handleClick} className={triggerClasses}>
                <div className="date-range-picker-trigger__section">
                    <TextField
                        size="small"
                        variant="standard"
                        value={formatPlainDate(t, fromDate)}
                        label={t('date.range.selector.from')}
                        className="date-range-picker-trigger__input"
                    />
                </div>
                <span className="date-range-picker-trigger__middle"/>
                <div className="date-range-picker-trigger__section">
                    <TextField
                        size="small"
                        variant="standard"
                        value={formatPlainDate(t, toDate)}
                        label={t('date.range.selector.to')}
                        className="date-range-picker-trigger__input"
                    />
                </div>
            </div>
            {isError && errorMessage && (
                <div className="date-range-picker__error-message">
                    {errorMessage}
                </div>
            )}
            {
                staticCalendar ? calendar : (
                    <Popover
                        id={isOpen ? 'simple-popover' : undefined}
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {calendar}
                    </Popover>
                )
            }
        </div>
    );
}

export default DateRangePicker;