import React from 'react';
import EventModel from "api/model/EventModel";
import {useTranslation} from "react-i18next";
import ItemTag from "components/common/ItemTag";
import "./styles.css";
import {Divider} from "@mui/material";

interface EventDetailsCategoriesProps {
    event: EventModel
}

const EventDetailsCategories = (props: EventDetailsCategoriesProps) => {
    const {t} = useTranslation();
    const {event} = props;
    let hasRequested = event.allowedCategories && event.allowedCategories.length > 0;
    let hasProhibited = event.prohibitedCategories && event.prohibitedCategories.length > 0;
    if (!hasProhibited && !hasRequested) {
        return <></>;
    }
    return (
        <div className="event-details-categories">
            {
                hasRequested && (
                    <div className="event-details-categories__required">
                        <div className="event-details-categories__title">
                            {t('event.details.page.categories.required')}
                        </div>
                        <div className="event-details-categories__required-tags">
                            {
                                event.allowedCategories?.map(cat => (
                                    <ItemTag size="L" type="Success">
                                        {t(`product.category.${cat}`)}
                                    </ItemTag>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                hasRequested && hasProhibited && (
                    <Divider orientation="vertical" flexItem variant="middle"/>
                )
            }
            {
                hasProhibited && (
                    <div className="event-details-categories__prohibited">
                        <div className="event-details-categories__title">
                            {t('event.details.page.categories.prohibited')}
                        </div>
                        <div className="event-details-categories__prohibited-tags">
                            {
                                event.prohibitedCategories?.map(cat => (
                                    <ItemTag size="L" type="Error">
                                        {t(`product.category.${cat}`)}
                                    </ItemTag>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default EventDetailsCategories;