import React from 'react';
import {CircularProgress} from "@mui/material";
import "./styles.css";

const LoadingPageContent = () => {
    return (
        <div className="loading-page">
            <CircularProgress/>
        </div>
    )
}

export default LoadingPageContent;