import React from 'react';
import {useTranslation} from "react-i18next";
import EventModel from "api/model/EventModel";
import ItemTag from "components/common/ItemTag";
import "./styles.css";

interface EventDetailsExtraProps {
    event: EventModel
}

const EventDetailsExtras = (props: EventDetailsExtraProps) => {
    const {t} = useTranslation();
    const {
        event: {
            extras
        }
    } = props;
    if (!extras) {
        return <></>;
    }
    const {
        isIndoor,
        isOutdoor,
        hasWater,
        hasDrainage,
        hasElectricity,
        hasSalesCounter,
        allowOwnSalesCounter,
        hasIndustrialElectricity,
        ownSalesCounterRequired,
    } = extras;
    return (
        <div className="event-details-extras">
            {
                isIndoor && (
                    <ItemTag>
                        <img src="/static/icons/inside.svg" alt="in-doors" />&nbsp;
                        {t('popup.details.page.extras.is.in.door')}
                    </ItemTag>
                )
            }
            {
                isOutdoor && (
                    <ItemTag>
                        <img src="/static/icons/outside.svg" alt="out-doors" />&nbsp;
                        {t('popup.details.page.extras.is.out.door')}
                    </ItemTag>
                )
            }
            {
                hasWater && (
                    <ItemTag>
                        <img src="/static/icons/faucet.svg" alt="water" />&nbsp;
                        {t('popup.details.page.extras.has.water')}
                    </ItemTag>
                )
            }
            {
                hasDrainage && (
                    <ItemTag>
                        <img src="/static/icons/drainage.svg" alt="drainage" />&nbsp;
                        {t('popup.details.page.extras.has.drainage')}
                    </ItemTag>
                )
            }
            {
                hasElectricity && (
                    <ItemTag>
                        <img src="/static/icons/electricity.svg" alt="electricity" />&nbsp;
                        {t('popup.details.page.extras.has.electricity')}
                    </ItemTag>
                )
            }
            {
                hasIndustrialElectricity && (
                    <ItemTag>
                        <img src="/static/icons/electricity.svg" alt="industrial-electricity" />&nbsp;
                        {t('popup.details.page.extras.has.industrial.electricity')}
                    </ItemTag>
                )
            }
            {
                hasSalesCounter && (
                    <ItemTag>
                        <img src="/static/icons/stall.svg" alt="stall" />&nbsp;
                        {t('popup.details.page.extras.has.counter')}
                    </ItemTag>
                )
            }
            {
                allowOwnSalesCounter && (
                    <ItemTag>
                        <img src="/static/icons/stall.svg" alt="own-stall" />&nbsp;
                        {t('popup.details.page.extras.allow.own.counter')}
                    </ItemTag>
                )
            }
            {
                ownSalesCounterRequired && (
                    <ItemTag>
                        <img src="/static/icons/stall.svg" alt="own-stall-required" />&nbsp;
                        {t('popup.details.page.extras.own.counter.required')}
                    </ItemTag>
                )
            }
        </div>
    );
}

export default EventDetailsExtras;
