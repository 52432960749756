import React from 'react';
import ErrorModel from "api/model/ErrorModel";
import {Trans, useTranslation} from "react-i18next";
import "./styles.css";

interface ErrorPageContentProps {
    error: ErrorModel
}

const ErrorPageContent = (props: ErrorPageContentProps) => {
    const {t} = useTranslation();
    const {error} = props;
    return (
        <div className="error-page">
            <div className="error-page-content">
                <h1>
                    {t(`error.page.${error.type}.title`)}
                </h1>
                <p>
                    <Trans t={t} i18nKey={`error.page.${error.type}.description`}>
                        Please try again. If the problem persists contact us at <a href="mailto:info@provente.io">info@provente.io</a>
                    </Trans>
                </p>
            </div>
        </div>
    )
}

export default ErrorPageContent;