import React, {useState} from "react";
import cx from "classnames";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";
import PlainDate, {comparePlainDate, getCurrentPlainDate} from "model/PlainDate";
import "./styles.css";
import {DateRangeModel} from "components/common/DateRangePicker/index";

interface CalendarProps {
    viewMonth: number,
    viewYear: number,
    toDate?: PlainDate,
    fromDate?: PlainDate,
    selectDate: (date: PlainDate) => void,
    blockedDates?: DateRangeModel[],
    availableDates?: DateRangeModel[],
    enableDatesFrom?: PlainDate,
}

interface CalendarDays extends PlainDate {
    weekday: number,
}

const getDayData = (viewMonth: number, viewYear: number) => {
    const viewDate = DateTime.fromObject({
        day: 1,
        month: viewMonth,
        year: viewYear,
    });

    const previousMonth = DateTime.fromObject({
        day: 1,
        month: viewMonth > 1 ? viewMonth - 1 : 12,
        year: viewMonth > 1 ? viewYear : viewYear - 1,
    });

    const nextMonth = DateTime.fromObject({
        day: 1,
        month: viewMonth === 12 ? 1 : viewMonth + 1,
        year: viewMonth === 12 ? viewYear + 1 : viewYear,
    });

    const viewFirstWeekday = viewDate.weekday;

    const calendar: CalendarDays[][] = [];
    const firstWeek: CalendarDays[] = [];
    if (viewFirstWeekday > 1) {
        const previousMonthTotalDays = previousMonth.daysInMonth || 31;

        for (let i = 1; i < viewFirstWeekday; i++) {
            const previousMonthDay = previousMonthTotalDays - (viewFirstWeekday - 1) + i;
            firstWeek.push({
                weekday: i,
                day: previousMonthDay,
                month: previousMonth.month,
                year: previousMonth.year,
            });
        }
    }

    const currentMonthTotalDays = viewDate.daysInMonth || 31;

    let iterationWeekday = viewFirstWeekday;
    let week: CalendarDays[] = firstWeek;
    for (let i = 1; i <= currentMonthTotalDays; i++) {
        week.push({
            weekday: iterationWeekday,
            day: i,
            month: viewMonth,
            year: viewYear,
        });
        if (iterationWeekday === 7) {
            iterationWeekday = 1;
            calendar.push(week);
            week = [];
        } else {
            iterationWeekday++;
        }
    }

    if (iterationWeekday > 1) {
        let nextMonthDay = 0;
        for (let i = iterationWeekday; i <= 7; i++) {
            nextMonthDay++;
            week.push({
                weekday: i,
                day: nextMonthDay,
                month: nextMonth.month,
                year: nextMonth.year,
            });
        }
    }

    if (week.length > 0) {
        calendar.push(week);
    }
    return calendar;
}

const CalendarGrid = (props: CalendarProps) => {
    const {t} = useTranslation();
    const {
        viewMonth,
        viewYear,
        selectDate,
        toDate,
        fromDate,
        blockedDates,
        availableDates,
        enableDatesFrom,
    } = props;

    const [hoverDate, setHoverDate] = useState<PlainDate | undefined>();

    const currentDate = getCurrentPlainDate();

    const days = getDayData(viewMonth, viewYear);

    return (
        <div className="date-range-picker-calendar-grid">
            <table>
                <thead>
                <tr>
                    <th>
                        {t('calendar.monday')}
                    </th>
                    <th>
                        {t('calendar.tuesday')}
                    </th>
                    <th>
                        {t('calendar.wednesday')}
                    </th>
                    <th>
                        {t('calendar.thursday')}
                    </th>
                    <th>
                        {t('calendar.friday')}
                    </th>
                    <th>
                        {t('calendar.saturday')}
                    </th>
                    <th>
                        {t('calendar.sunday')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    days.map((week, idx) => (
                        <tr key={idx}>
                            {
                                week.map(day => {
                                    const offMonthDay = day.month !== viewMonth;
                                    const selected = (fromDate && comparePlainDate(fromDate, day) === 0) || (toDate && comparePlainDate(toDate, day) === 0);
                                    const isCurrent = comparePlainDate(day, currentDate) === 0;

                                    let isInRange = false;
                                    if (fromDate) {
                                        const isAfterFrom = comparePlainDate(day, fromDate) > 0;
                                        if (hoverDate) {
                                            if (isAfterFrom && comparePlainDate(day, hoverDate) < 0) {
                                                isInRange = true;
                                            }
                                        } else if (isAfterFrom && toDate && comparePlainDate(day, toDate) < 0) {
                                            isInRange = true;
                                        }
                                    }

                                    let isBlocked = false;
                                    let isUnavailable = false;
                                    if (comparePlainDate(day, currentDate) <= 0) {
                                        isUnavailable = true;
                                    } else if (enableDatesFrom && comparePlainDate(day, enableDatesFrom) < 0) {
                                        isUnavailable = true;
                                    } else if (availableDates && availableDates.length > 0) {
                                        isUnavailable = !availableDates?.find((available) => comparePlainDate(day, available.from) >= 0 && comparePlainDate(day, available.to) <= 0);
                                    } else if (blockedDates && blockedDates.length > 0) {
                                        isBlocked = !!blockedDates?.find((blocked) => comparePlainDate(day, blocked.from) >= 0 && comparePlainDate(day, blocked.to) <= 0);
                                    }

                                    const classes = cx({
                                        'date-range-picker-calendar-grid__day': true,
                                        'date-range-picker-calendar-grid__day--range': isInRange,
                                        'date-range-picker-calendar-grid__day--selected': selected,
                                        'date-range-picker-calendar-grid__day--current': isCurrent,
                                        'date-range-picker-calendar-grid__day--weekend': !offMonthDay && day.weekday > 5,
                                        'date-range-picker-calendar-grid__day--off': offMonthDay,
                                        'date-range-picker-calendar-grid__day--blocked': isBlocked,
                                        'date-range-picker-calendar-grid__day--unavailable': isUnavailable,
                                    });
                                    return (
                                        <td key={`${day.day}-${day.month}-${day.year}`}>
                                            <div
                                                className={classes}
                                                onClick={() => {
                                                    if (!isUnavailable && !isBlocked) {
                                                        selectDate(day)
                                                    }
                                                }}
                                                onMouseEnter={() => {
                                                    if (day.month === viewMonth && day.year === viewYear) {
                                                        setHoverDate(day);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverDate((prevState) => {
                                                        if (day.month === viewMonth && day.year === viewYear) {
                                                            if (prevState && comparePlainDate(prevState, day) === 0) {
                                                                return undefined;
                                                            }
                                                        }
                                                        return prevState;
                                                    });
                                                }}
                                            >
                                                {day.day}
                                            </div>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
};

export default CalendarGrid;
