import {DateTime, DurationLike} from "luxon";

export default interface PlainDate {
    year: number,
    month: number,
    day: number,
}

type PlainDateDurationType = 'days' | 'months' | 'years';

export interface PlainDateDuration {
    duration: number,
    type: PlainDateDurationType,
}

export const modifyPlainDate = (date: PlainDate, duration: PlainDateDuration) => {
    let dateTime = DateTime.fromObject({
        day: date.day,
        month: date.month,
        year: date.year,
    });
    let luxonDuration: DurationLike | undefined;
    switch (duration.type) {
        case "days":
            luxonDuration = {
                day: Math.abs(duration.duration)
            };
            break;
        case "months":
            luxonDuration = {
                month: Math.abs(duration.duration)
            };
            break;
        case "years":
            luxonDuration = {
                year: Math.abs(duration.duration)
            };
            break;
    }
    if (luxonDuration) {
        if (duration.duration < 0) {
            dateTime = dateTime.minus(luxonDuration);
        } else {
            dateTime = dateTime.plus(luxonDuration);
        }
        date.day = dateTime.day;
        date.month = dateTime.month;
        date.year = dateTime.year;
    }
}

export const getCurrentPlainDate = (): PlainDate => {
    const currentDate = DateTime.now();
    return {
        day: currentDate.day,
        month: currentDate.month,
        year: currentDate.year,
    };
}

export const plainDateToISO = (date: PlainDate): string => {
    const day: string = formatNumberAsDate(date.day);
    const month: string = formatNumberAsDate(date.month);
    return `${date.year}-${month}-${day}`;
}

export const plainDateFromISO = (iso: string): PlainDate | undefined => {
    if (iso && iso.length > 0) {
        const dateTime = DateTime.fromISO(iso, {setZone: false});
        if (dateTime.isValid) {
            return {
                year: dateTime.year,
                month: dateTime.month,
                day: dateTime.day,
            };
        }
    }
    return;
}

export const comparePlainDate = (a: PlainDate, b: PlainDate) => {
    const yearDiff = a.year - b.year;
    const monthDiff = a.month - b.month;
    const dayDiff = a.day - b.day;
    if (yearDiff !== 0) {
        return yearDiff;
    }
    if (monthDiff !== 0) {
        return monthDiff;
    }
    return dayDiff;
}

const formatNumberAsDate = (number: number): string => {
    if (number < 10) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
}

export const plainDateRangeToDisplayString = (from: PlainDate, to: PlainDate) => {
    let fromString = `${formatNumberAsDate(from.day)}.${formatNumberAsDate(from.month)}`;
    if (from.year !== to.year) {
        fromString = `${fromString}.${from.year}`;
    }
    const toString = `${formatNumberAsDate(to.day)}.${formatNumberAsDate(to.month)}.${to.year}`;
    if (comparePlainDate(from, to) === 0) {
        return toString;
    }
    return `${fromString} - ${toString}`;
}