import {SupportedLanguage} from "consts/LanguageConsts";
import {countriesArray, CountryType} from "consts/CountryConsts";
import {getCountryTranslation, getDefaultTranslation} from "locales/static/countries";

export interface CountryInterface {
    iso: CountryType,
    label: string,
}

export const getCountriesByLanguage = (language: SupportedLanguage) => {
    const response: CountryInterface[] = [];
    for (const country of countriesArray) {
        let translation = getCountryTranslation(country, language);
        if (!translation) {
            translation = getDefaultTranslation(country);
        }
        response.push({
            iso: country,
            label: translation,
        });
    }
    return response;
};

export const getCountryByLanguageIso = (language?: SupportedLanguage): CountryInterface | undefined => {
    if (language) {
        switch (language) {
            case "et": {
                const translation = getCountryTranslation("EE", language);
                if (translation) {
                    return {
                        iso: "EE",
                        label: translation,
                    };
                }
                return;
            }
            case "lv": {
                const translation = getCountryTranslation("LV", language);
                if (translation) {
                    return {
                        iso: "LV",
                        label: translation,
                    };
                }
                return;
            }
            default:
                return;
        }
    }
}
