import React from 'react';
import PopupDetailsModel from "api/model/PopupDetailsModel";
import "./styles.css";
import {useTranslation} from "react-i18next";
import {Divider} from "@mui/material";

interface PopupDetailsHeaderProps {
    details: PopupDetailsModel
}

const PopupDetailsHeader = (props: PopupDetailsHeaderProps) => {
    const {t} = useTranslation();
    const {
        details: {
            popup,
            property,
        }
    } = props;
    return (
        <div className="popup-details-header">
            <div className="popup-details-header__popup">
                <div className="popup-details-header__title">
                    <img src="/static/icons/popup-gray.svg" alt="popup"/>&nbsp;
                    <span>{t('popup.details.page.popup.title')}</span>
                </div>
                <h2>
                    {popup.name}
                </h2>
                <p>
                    {popup.descriptionShort}
                </p>
            </div>
            <Divider orientation="vertical" flexItem variant="middle"/>
            <div className="popup-details-header__property">
                <div className="popup-details-header__title">
                    <span>{t('popup.details.page.property.title')}</span>&nbsp;
                    <img src="/static/icons/property-gray.svg" alt="property"/>
                </div>
                <h2>
                    {property.name}
                </h2>
                <p>
                    {property.location.address}, {property.location.town}
                </p>
            </div>
        </div>
    )
}

export default PopupDetailsHeader;