import React, {useState} from 'react';
import cx from "classnames";
import {Button, CircularProgress} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MapIcon from "@mui/icons-material/Map";
import './styles.css';

interface SearchResultsWrapper {
    isLoading: boolean,
    list: any,
    map: any,
}

const SearchResults = (props: SearchResultsWrapper) => {
    const {isLoading, list, map} = props;
    const [showMap, setShowMap] = useState(false);
    const listClasses = cx({
        'search-results__list': true,
        'search-results__list--open': !showMap,
        'search-results__list--loading': isLoading,
    });

    const mapClasses = cx({
        'search-results__map': true,
        'search-results__map--open': showMap,
    });
    return (
        <>
            <div className="search-results__mobile-toolbar">
                <Button onClick={() => setShowMap(!showMap)}>
                    {
                        showMap ?
                            <FormatListBulletedIcon color="action" fontSize="large"/> :
                            <MapIcon color="action" fontSize="large"/>
                    }
                </Button>
            </div>
            <div className="search-results">
                <div className={listClasses}>
                    {
                        isLoading ? (
                            <CircularProgress/>
                        ) : list
                    }
                </div>
                <div className={mapClasses}>
                    {map}
                </div>
            </div>
        </>
    );
}

export default SearchResults;