import React, {useEffect} from 'react';
import {useMap} from "@vis.gl/react-google-maps";
import {getBounds} from "geolib";
import {MarkerInfo} from "components/Search/SearchMap";
import EventModel from "api/model/EventModel";
import EventMapMarkerClusterer from "components/Search/EventSearch/EventMapMarkerClusterer";

interface EventMapProps {
    events?: EventModel[],
    setMarkerInfo: (info: MarkerInfo | null) => void,
}

const EventMap = (props: EventMapProps) => {
    const {events = [], setMarkerInfo} = props;
    const map = useMap();
    useEffect(() => {
        if (events.length > 0 && map) {
            if (events.length === 1) {
                const [event] = events;
                map.panTo(event.location.position);
                map.setZoom(15);
            } else {
                const locations = events.map(event => event.location.position);
                const bounds = getBounds(locations);
                map.fitBounds({
                    north: bounds.maxLat,
                    south: bounds.minLat,
                    east: bounds.maxLng,
                    west: bounds.minLng,
                }, {
                    bottom: 200,
                    top: 200,
                    right: 50,
                    left: 50,
                });
            }
        }
    }, [events, map]);
    return (
        <EventMapMarkerClusterer setMarkerInfo={setMarkerInfo} events={events}/>
    );
};

export default EventMap;