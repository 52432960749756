import React from 'react';
import {AxiosError} from "axios";
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {useMutation} from "@tanstack/react-query";
import {handleRequestError} from "api/helper/ErrorHelper";
import {ErrorType} from "api/model/ErrorType";
import {applyPopup} from "api/actions/ApplicationActions";
import PopupModel from "api/model/PopupModel";
import PlainDate, {getCurrentPlainDate, modifyPlainDate} from "model/PlainDate";
import ApplicationForm, {ApplicationFormModel} from "components/common/ApplicationForm";
import {SupportedLanguage} from "consts/LanguageConsts";
import {useLocationStore} from "store/locationStore";
import "./styles.css";

interface PopupDetailsApplicationProps {
    popup: PopupModel
}

const knownRequestErrors: ErrorType[] = ["INVALID_PARAMETERS", "RESOURCE_NOT_FOUND"];

const PopupDetailsApplication = (props: PopupDetailsApplicationProps) => {
    const countryIso = useLocationStore(state => state.location?.countryIso) || 'EE';
    const {t, i18n} = useTranslation();
    const {popup} = props;
    const {
        mutate,
        isPending,
        isSuccess,
    } = useMutation({
        mutationFn: (request: ApplicationFormModel) => {
            return applyPopup(popup.id, {
                ...request,
                dates: {
                    from: request.dates.from,
                    to: request.dates.to,
                },
                languageIso: i18n.language as SupportedLanguage,
                files: request.files.filter(file => file.uuid).map(file => file.uuid || ''),
                countryIso,
            });
        },
        onError: (axiosError: AxiosError) => handleRequestError(axiosError, error => {
            if (error) {
                if (knownRequestErrors.includes(error.type)) {
                    toast(t(`popup.details.page.application.apply.error.${error.type}`), {type: "error"});
                } else {
                    toast(t(`common.errors.${error.type}`), {type: "error"});
                }
            }
        }),
        onSuccess: () => {
            toast(t('popup.details.page.application.apply.success'), {type: "success"});
        },
    });

    const apply = (data: ApplicationFormModel) => {
        mutate(data);
    };
    let allowApplyingFrom: PlainDate | undefined;
    if (popup.applyingMinAdvancedDays > 0) {
        allowApplyingFrom = getCurrentPlainDate();
        modifyPlainDate(
            allowApplyingFrom,
            {
                type: "days",
                duration: popup.applyingMinAdvancedDays + 1,
            }
        );
    }

    return (
        <ApplicationForm
            apply={apply}
            isLoading={isPending}
            clearFields={isSuccess}
            disabledDates={popup.bookings}
            allowApplyingFrom={allowApplyingFrom}
        />
    );
}

export default PopupDetailsApplication;