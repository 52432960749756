import {createTheme} from "@mui/material";

interface ThemeModel {
    color: {
        primary: string,
        secondary: string,
    }
}

export const theme: ThemeModel = {
    color: {
        primary: '#ff971b',
        // secondary: '#49B2AA'
        secondary: '#00887a'
    }
};

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: theme.color.primary,
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: theme.color.secondary,
            contrastText: '#FFFFFF'
        },
    },
});