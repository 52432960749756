import React from "react";
import urlSlug from "url-slug";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EventModel from "api/model/EventModel";
import ItemTag from "components/common/ItemTag";
import "./styles.css";
import {Divider} from "@mui/material";
import EventPrices from "components/Search/EventSearch/EventPrices";

interface EventInfoProps {
    event: EventModel,
}

const EventMapInfo = (props: EventInfoProps) => {
    const {t} = useTranslation();
    const {
        event,
    } = props;
    return (
        <div className="event-info">
            <Link
                target="_blank"
                to={`/event/${event.id}/${urlSlug(`${event.name}`)}`}
            >
                <div className="event-info__title">
                    {event.name}
                </div>
                {
                    event.keyPhoto && (
                        <div className="event-info__image">
                            <img
                                src={event.keyPhoto} alt={event.name}
                            />
                        </div>
                    )
                }
                <div className="event-info__data">
                    <div className="event-info__description">
                        {event.descriptionShort}
                    </div>
                    <div className="event-info__tags">
                        {
                            event.allowedCategories?.map(cat => (
                                <ItemTag type="Success">
                                    {t(`product.category.${cat}`)}
                                </ItemTag>
                            ))
                        }
                    </div>
                    <br/>
                    <Divider/>
                    <EventPrices prices={event.prices}/>
                </div>
            </Link>
        </div>
    )
}

export default EventMapInfo;