import React from 'react';
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import FestivalIcon from '@mui/icons-material/Festival';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventModel from "api/model/EventModel";
import "./styles.css";
import {plainDateRangeToDisplayString} from "model/PlainDate";

interface EventDetailsHeaderProps {
    event: EventModel
}

const EventDetailsHeader = (props: EventDetailsHeaderProps) => {
    const {t} = useTranslation();
    const {
        event
    } = props;
    return (
        <div className="event-details-header">
            <div className="event-details-header__event">
                <div className="event-details-header__title">
                    <FestivalIcon/>&nbsp;
                    <span>{t('event.details.page.event.title')}</span>
                </div>
                <h2>
                    {event.name}
                </h2>
                <div className="event-details-header__dates">
                    {plainDateRangeToDisplayString(event.dateStart, event.dateEnd)}
                </div>
                <p>
                    {event.descriptionShort}
                </p>
            </div>
            <Divider className="event-details-header__divider" orientation="vertical" flexItem variant="middle"/>
            <div className="event-details-header__property">
                <div className="event-details-header__title">
                    <span>{t('event.details.page.location.title')}</span>&nbsp;
                    <LocationOnIcon/>
                </div>
                <h2>
                    {event.location.town}
                </h2>
                <p>
                    {event.location.address}, {event.location.zipCode}
                </p>
            </div>
        </div>
    )
}

export default EventDetailsHeader;