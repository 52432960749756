import {CountryLocaleModel} from "locales/static/countries";

export const et: CountryLocaleModel = {
    EE: "Eesti",
    LV: "Läti",
    LT: "Leedu",
    AD: "Andorra",
    AF: "Afganistan",
    AG: "Antigua ja Barbuda",
    AI: "Anguilla",
    AL: "Albaania",
    AM: "Armeenia",
    AO: "Angola",
    AQ: "Antarktika",
    AR: "Argentiina",
    AS: "Ameerika Samoa",
    AT: "Austria",
    AU: "Austraalia",
    AW: "Aruba",
    AX: "Ahvenamaa",
    AZ: "Aserbaidžaan",
    BA: "Bosnia ja Hertsegoviina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgia",
    BF: "Burkina Faso",
    BG: "Bulgaaria",
    BH: "Bahrein",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint-Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Boliivia",
    BQ: "Bonaire, Sint Eustatius ja Saba",
    BR: "Brasiilia",
    BT: "Bhutan",
    BV: "Bouvet' saar",
    BW: "Botswana",
    BY: "Valgevene",
    BZ: "Belize",
    CA: "Kanada",
    CH: "Šveits",
    CI: "Côte d'Ivoire",
    CL: "Tšiili",
    CM: "Kamerun",
    CN: "Hiina",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Kuuba",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CX: "Jõulusaar",
    CY: "Küpros",
    DE: "Saksamaa",
    DJ: "Djibouti",
    DK: "Taani",
    DM: "Dominica",
    DZ: "Alžeeria",
    EC: "Ecuador",
    EG: "Egiptus",
    EH: "Lääne-Sahara",
    ER: "Eritrea",
    ES: "Hispaania",
    ET: "Etioopia",
    FI: "Soome",
    FJ: "Fidži",
    FM: "Mikroneesia",
    FR: "Prantsusmaa",
    GA: "Gabon",
    GD: "Grenada",
    GE: "Gruusia",
    GF: "Prantsuse Guajaana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Gröönimaa",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Ekvatoriaal-Guinea",
    GR: "Kreeka",
    GS: "Lõuna-Georgia ja Lõuna-Sandwichi saared",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hongkong",
    HN: "Honduras",
    HR: "Horvaatia",
    HT: "Haiti",
    HU: "Ungari",
    ID: "Indoneesia",
    IE: "Iirimaa",
    IL: "Iisrael",
    IM: "Mani saar",
    IN: "India",
    IO: "Briti India ookeani territooriumid",
    IQ: "Iraak",
    IR: "Iraan",
    IS: "Island",
    IT: "Itaalia",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordaania",
    JP: "Jaapan",
    KE: "Keenia",
    KG: "Kõrgõzstan",
    KH: "Kambodža",
    KP: "Põhja-Korea",
    KR: "Lõuna-Korea",
    KI: "Kiribati",
    KN: "Saint Kitts ja Nevis",
    KW: "Kuveit",
    KZ: "Kasahstan",
    LB: "Liibanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Libeeria",
    LS: "Lesotho",
    LU: "Luksemburg",
    LY: "Liibüa",
    MA: "Maroko",
    MC: "Monaco",
    ME: "Montenegro",
    MF: "Saint-Martin (Prantsuse osa)",
    MG: "Madagaskar",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongoolia",
    MO: "Macau",
    MQ: "Martinique",
    MR: "Mauritaania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldiivid",
    MW: "Malawi",
    MX: "Mehhiko",
    MY: "Malaisia",
    MZ: "Mosambiik",
    NA: "Namiibia",
    NC: "Uus-Kaledoonia",
    NF: "Norfolk",
    NG: "Nigeeria",
    NI: "Nicaragua",
    NO: "Norra",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "Uus-Meremaa",
    OM: "Omaan",
    PA: "Panama",
    PE: "Peruu",
    PF: "Prantsuse Polüneesia",
    PG: "Paapua Uus-Guinea",
    PK: "Pakistan",
    PL: "Poola",
    PM: "Saint-Pierre ja Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestiina",
    PT: "Portugal",
    PW: "Belau",
    PY: "Paraguay",
    QA: "Katar",
    RE: "Réunion",
    RO: "Rumeenia",
    RS: "Serbia",
    RU: "Venemaa",
    RW: "Rwanda",
    SA: "Saudi Araabia",
    SB: "Saalomoni Saared",
    SC: "Seišellid",
    SE: "Rootsi",
    SG: "Singapur",
    SH: "Saint Helena, Ascension ja Tristan da Cunha",
    SI: "Sloveenia",
    SJ: "Svalbard ja Jan Mayen",
    SK: "Slovakkia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somaalia",
    SR: "Suriname",
    SS: "Lõuna-Sudaan",
    ST: "São Tomé ja Príncipe",
    SV: "El Salvador",
    SX: "Sint Maarten (Hollandi osa)",
    SY: "Süüria Araabia Vabariik",
    TD: "Tšaad",
    TG: "Togo",
    TH: "Tai",
    TJ: "Tadžikistan",
    TK: "Tokelau",
    TL: "Ida-Timor",
    TM: "Türkmenistan",
    TN: "Tuneesia",
    TO: "Tonga",
    TR: "Türgi",
    TT: "Trinidad ja Tobago",
    TV: "Tuvalu",
    TZ: "Tansaania Ühendvabariik",
    UA: "Ukraina",
    UG: "Uganda",
    US: "Ameerika Ühendriigid",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VC: "Saint Vincent ja Grenadiinid",
    VE: "Venezuela",
    VG: "Briti Neitsisaared",
    VI: "USA Neitsisaared",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis ja Futuna",
    WS: "Samoa",
    YE: "Jeemen",
    YT: "Mayotte",
    ZA: "Lõuna-Aafrika",
    ZM: "Sambia",
    ZW: "Zimbabwe",
    SZ: "Esvatini",
    MK: "Põhja-Makedoonia",
    PH: "Filipiinid",
    NL: "Holland",
    AE: "Araabia Ühendemiraadid",
    MD: "Moldova Vabariik",
    GM: "Gambia",
    DO: "Dominikaani Vabariik",
    SD: "Sudaan",
    LA: "Laose Demokraatlik Rahvavabariik",
    TW: "Taiwan",
    CG: "Kongo Vabariik",
    CZ: "Tšehhi",
    GB: "Ühendkuningriik",
    NE: "Niger",
    CD: "Kongo Demokraatlik Vabariik",
    BS: "Bahama Ühendus",
    CC: "Kookossaared",
    CF: "Kesk-Aafrika Vabariik",
    CK: "Cooki Saared",
    FK: "Falklandi saared",
    FO: "Fääri saared",
    HM: "Heard ja McDonaldi saared",
    KM: "Komoorid",
    KY: "Kaimani Saared",
    MH: "Marshalli Saared",
    MP: "Põhja-Mariaanid",
    TC: "Turks ja Caicos",
    TF: "Prantsuse Lõunaalad",
    UM: "USA hajasaared",
    VA: "Vatikan",
    XK: "Kosovo Vabariik",
    AN: "Hollandi Antillid"
}
