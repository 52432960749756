import React from 'react';
import PageWithHeader from "components/common/PageWithHeader";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import useTitle from "lib/useTitle";
import EventDetails from "components/EventDetails";
import {getEventDetails} from "api/actions/EventActions";
import EventModel from "api/model/EventModel";
import {AxiosError} from "axios";
import ErrorModel from "api/model/ErrorModel";

const EventDetailsPage = () => {
    const {eventId = ''} = useParams();
    const {
        data,
        isLoading,
        error,
    } = useQuery<EventModel, AxiosError<ErrorModel>>({
        queryKey: ['event-details-query', eventId],
        queryFn: () => getEventDetails(parseInt(eventId, 10)),
        retry: false,
    });
    useTitle(data && `${data?.name} @ ${data?.location.town} | Provente.io`);
    return (
        <PageWithHeader error={error?.response?.data} searchType="events" isLoading={isLoading}>
            {
                data && <EventDetails event={data}/>
            }
        </PageWithHeader>
    );
}

export default EventDetailsPage;