import axios from "axios";
import {FilterFormModel} from "components/Search/SearchFilter";
import {paramsToUri} from "lib/SearchHelper";
import EventModel from "api/model/EventModel";

export const searchEvents = async (searchRequest?: FilterFormModel): Promise<EventModel[]> => {
    const params = paramsToUri(searchRequest);
    return await axios.get<EventModel[]>(`/api/events${params ? '?' + params : ''}`).then(res => res.data);
};

export const getEventDetails = (eventId: number): Promise<EventModel> => axios.get<EventModel>(`/api/events/${eventId}`).then(res => res.data);
