import axios from "axios";
import PopupDetailsModel from "api/model/PopupDetailsModel";
import PopupSearchResponse from "api/model/PopupSearchResponse";
import {FilterFormModel} from "components/Search/SearchFilter";
import {paramsToUri} from "lib/SearchHelper";

export const searchPopups = async (searchRequest?: FilterFormModel): Promise<PopupSearchResponse> => {
    const params = paramsToUri(searchRequest);
    return await axios.get<PopupSearchResponse>(`/api/popups${params ? '?' + params : ''}`).then(res => res.data);
};

export const getPopupDetails = (popupId: number): Promise<PopupDetailsModel> => axios.get<PopupDetailsModel>(`/api/popups/${popupId}`).then(res => res.data);
