import {CountryLocaleModel} from "locales/static/countries";

export const en: CountryLocaleModel = {
    EE: "Estonia",
    LV: "Latvia",
    LT: "Lithuania",
    AD: "Andorra",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BM: "Bermuda",
    BN: "Brunei Darussalam",
    BO: "Bolivia",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BR: "Brazil",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CH: "Switzerland",
    CI: "Côte d'Ivoire",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CX: "Christmas Island",
    CY: "Cyprus",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DZ: "Algeria",
    EC: "Ecuador",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FM: "Micronesia (Federated States of)",
    FR: "France",
    GA: "Gabon",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia and the South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territories",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KP: "North Korea",
    KR: "South Korea",
    KI: "Kiribati",
    KN: "Saint Kitts and Nevis",
    KW: "Kuwait",
    KZ: "Kazakhstan",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LU: "Luxembourg",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    ME: "Montenegro",
    MF: "Saint Martin (French part)",
    MG: "Madagascar",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestine",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    SI: "Slovenia",
    SJ: "Svalbard and Jan Mayen",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "Republic of San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "Sao Tome and Principe",
    SV: "El Salvador",
    SX: "Sint Maarten (Dutch part)",
    SY: "Syrian Arab Republic",
    TD: "Chad",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TZ: "United Republic of Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    US: "United States of America",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    SZ: "Eswatini",
    MK: "North Macedonia",
    PH: "Philippines",
    NL: "Netherlands",
    AE: "United Arab Emirates",
    MD: "Republic of Moldova",
    GM: "Gambia",
    DO: "Dominican Republic",
    SD: "Sudan",
    LA: "Lao People's Democratic Republic",
    TW: "Taiwan",
    CG: "Republic of the Congo",
    CZ: "Czechia",
    GB: "United Kingdom",
    NE: "Niger",
    CD: "Democratic Republic of the Congo",
    BS: "Commonwealth of The Bahamas",
    CC: "Cocos (Keeling) Islands",
    CF: "Central African Republic",
    CK: "Cook Islands",
    FK: "Falkland Islands",
    FO: "Faroe Islands",
    HM: "Territory of Heard Island and McDonald Islands",
    KM: "Comoros",
    KY: "Cayman Islands",
    MH: "Republic of the Marshall Islands",
    MP: "Commonwealth of the Northern Mariana Islands",
    TC: "Turks and Caicos Islands",
    TF: "French Southern and Antarctic Lands",
    UM: "United States Minor Outlying Islands",
    VA: "Holy See",
    XK: "Republic of Kosovo",
    AN: "Netherlands Antilles",
}
