import React, {useCallback, useEffect, useMemo} from "react";
import {v4 as uuidV4} from "uuid";
import {AdvancedMarker, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import FestivalIcon from '@mui/icons-material/Festival';
import EventModel from "api/model/EventModel";
import {MarkerInfo} from "components/Search/SearchMap";
import EventMapInfo from "components/Search/EventSearch/EventMapInfo";
import {EventMarkerModel} from "components/Search/EventSearch/EventMapMarkerClusterer";
import "./styles.css";

interface EventMarkerProps {
    showInfo: (markerInfo: MarkerInfo) => void,
    event: EventModel,
    setRef: (ref: EventMarkerModel) => void,
    removeRef: (id: string) => void,
}

const EventMapMarker = (props: EventMarkerProps) => {
    const {
        event,
        setRef,
        showInfo,
        removeRef,
    } = props;
    const [markerRef, marker] = useAdvancedMarkerRef();
    const id = useMemo(() => {
        return uuidV4();
    }, []);
    const handleMarkerClick = useCallback(() => marker && showInfo({
        marker,
        // title: (<h2 className="event-search__map--info-title">{event.name}</h2>),
        title: '',
        content: (
            <EventMapInfo
                event={event}
            />
        ),
    }), [marker]);
    useEffect(() => {
        if (marker) {
            setRef({
                id,
                marker,
            });
            return () => removeRef(id);
        }
    }, [marker]);
    if (!event) {
        return <></>;
    }
    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={event.location.position}
                title={event.name}
                onClick={handleMarkerClick}
            >
                <div className="event-search__marker">
                    <FestivalIcon fontSize="small" />
                </div>
            </AdvancedMarker>
        </>
    )
}

export default EventMapMarker;
