import React from 'react';
import PopupModel from "api/model/PopupModel";
import {useTranslation} from "react-i18next";
import ItemTag from "components/common/ItemTag";
import "./styles.css";
import {Divider} from "@mui/material";

interface PopupDetailsCategoriesProps {
    popup: PopupModel
}

const PopupDetailsCategories = (props: PopupDetailsCategoriesProps) => {
    const {t} = useTranslation();
    const {popup} = props;
    let hasRequested = popup.requestedProductCategories && popup.requestedProductCategories.length > 0;
    let hasProhibited = popup.prohibitedProductCategories && popup.prohibitedProductCategories.length > 0;
    if (!hasProhibited && !hasRequested) {
        return <></>;
    }
    return (
        <div className="popup-details-categories">
            {
                hasRequested && (
                    <div className="popup-details-categories__required">
                        <div className="popup-details-categories__title">
                            {t('popup.details.page.categories.required')}
                        </div>
                        <div className="popup-details-categories__required-tags">
                            {
                                popup.requestedProductCategories?.map(cat => (
                                    <ItemTag key={cat} size="L" type="Success">
                                        {t(`product.category.${cat}`)}
                                    </ItemTag>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                hasRequested && hasProhibited && (
                    <Divider orientation="vertical" flexItem variant="middle"/>
                )
            }
            {
                hasProhibited && (
                    <div className="popup-details-categories__prohibited">
                        <div className="popup-details-categories__title">
                            {t('popup.details.page.categories.prohibited')}
                        </div>
                        <div className="popup-details-categories__prohibited-tags">
                            {
                                popup.prohibitedProductCategories?.map(cat => (
                                    <ItemTag key={cat} size="L" type="Error">
                                        {t(`product.category.${cat}`)}
                                    </ItemTag>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default PopupDetailsCategories;