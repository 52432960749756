import React from 'react';
import cx from 'classnames';
import "./styles.css";

type TagType = 'Standard' | 'Success' | 'Error';
type SizeType = 'S' | 'M' | 'L'

interface ItemTagProps {
    children: any,
    type?: TagType,
    size?: SizeType,
}

const ItemTag = (props: ItemTagProps) => {
    const {
        children,
        type = "Standard",
        size = "M",
    } = props;
    const classes = cx({
        'item-tag': true,
        'item-tag--standard': type === "Standard",
        'item-tag--success': type === "Success",
        'item-tag--error': type === "Error",
        'item-tag--small': size === "S",
        'item-tag--medium': size === "M",
        'item-tag--large': size === "L",
    });
    return (
        <span className={classes}>
            {children}
        </span>
    );
}

export default ItemTag;