import React, {useEffect, useState} from "react";
import {MarkerClusterer,} from "@googlemaps/markerclusterer";
import {useMap} from "@vis.gl/react-google-maps";
import PopupMapMarker from "components/Search/PopupSearch/PopupMapMarker";
import PopupSearchResponse from "api/model/PopupSearchResponse";
import CustomPopupClustererRenderer from "model/CustomPopupClustererRenderer";
import {MarkerInfo} from "components/Search/SearchMap";
import PropertyModel from "api/model/PropertyModel";
import PopupMapComingSoonMarker from "components/Search/PopupSearch/PopupMapComingSoonMarker";

interface PopupMarkerClustererProps {
    searchResponse?: PopupSearchResponse,
    setMarkerInfo: (info: MarkerInfo | null) => void,
}

export interface MarkerModel {
    marker: google.maps.marker.AdvancedMarkerElement,
    count: number,
    id: string,
}

const PopupMapMarkerClusterer = (props: PopupMarkerClustererProps) => {
    const {
        setMarkerInfo,
        searchResponse,
    } = props;
    const map = useMap();
    const [markers, setMarkers] = useState<MarkerModel[]>([]);
    const setMarker = (marker: MarkerModel) => {
        setMarkers(prevState => {
            return [...prevState, marker];
        });
    }
    const removeMarker = (id: string) => {
        setMarkers(prevState => {
            return prevState.filter(marker => marker.id !== id);
        });
    }
    useEffect(() => {
        const cluster = new MarkerClusterer({
            map,
            markers: markers.map(marker => marker.marker),
            renderer: new CustomPopupClustererRenderer(markers),
            onClusterClick: (event, cluster, map) => {
                if (cluster.bounds) {
                    map.fitBounds(cluster.bounds, {
                        bottom: 200,
                        top: 200,
                        right: 50,
                        left: 50,
                    });
                }
            }
        });
        return () => {
            cluster.setMap(null);
            cluster.unbindAll();
            cluster.clearMarkers();
        };
    }, [map, markers]);
    if (!searchResponse) {
        return <></>;
    }
    const {properties} = searchResponse;
    const availableProperties: PropertyModel[] = [];
    const comingSoonProperties: PropertyModel[] = [];
    for (const property of properties) {
        if (property.isComingSoon) {
            comingSoonProperties.push(property);
        } else {
            availableProperties.push(property);
        }
    }
    return (
        <>
            {
                availableProperties.map(property => (
                    <PopupMapMarker
                        key={property.id}
                        setRef={setMarker}
                        property={property}
                        showInfo={setMarkerInfo}
                        removeRef={removeMarker}
                        popupCounts={searchResponse.propertiesPopupCounts}
                        propertyPopups={searchResponse.popups.filter(popup => popup.propertyId === property.id)}
                    />
                ))
            }
            {
                comingSoonProperties.map(property => (
                    <PopupMapComingSoonMarker
                        key={property.id}
                        setRef={setMarker}
                        property={property}
                        showInfo={setMarkerInfo}
                        removeRef={removeMarker}
                    />
                ))
            }
        </>
    );
}

export default PopupMapMarkerClusterer;
