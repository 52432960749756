import React from "react";
import {useLocationStore} from "store/locationStore";
import "./styles.css";

const PageFooter = () => {
    const userCountry = useLocationStore(state => state.location?.countryIso);
    const contactEmail = userCountry === "LV" ? 'info@provente.lv' : 'info@provente.io';
    const domain = userCountry === "LV" ? 'provente.lv' : 'provente.io';
    return (
        <div className="page-footer">
            <span>{domain}</span>
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </div>
    );
}

export default PageFooter;