export const productCategories = [
    'FOOD',
    'DRINK',
    'COFFEE',
    'ALCOHOL',
    'HANDICRAFT',
    'MARKETING',
    'ELECTRONICS',
    'CLOTHING',
    'TOYS',
    'SEASONAL',
    'JEWELRY',
    'SWEETS',
    'ENTERTAINMENT'
] as const;

export type ProductCategory = typeof productCategories[number];
