import React, {useEffect, useRef, useState} from 'react';
import {Button, Divider} from "@mui/material";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReactImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
import PopupDetailsModel from "api/model/PopupDetailsModel";
import PopupModel from "api/model/PopupModel";
import PopupDetailsHeader from "components/PopupDetails/PopupDetailsHeader";
import PopupDetailsPrices from "components/PopupDetails/PopupDetailsPrices";
import PopupDetailsExtras from "components/PopupDetails/PopupDetailsExtras";
import PopupDetailsMap from "components/PopupDetails/PopupDetailsMap";
import PopupDetailsCategories from "components/PopupDetails/PopupDetailsCategories";
import PopupDetailsApplication from "components/PopupDetails/PopupDetailsApplication";
import {useTranslation} from "react-i18next";
import "./styles.css";

export interface PopupDetailsProps {
    details: PopupDetailsModel,
}

const generateImageObjects = (popup: PopupModel): ReactImageGalleryItem[] => {
    const response: ReactImageGalleryItem[] = [];
    if (popup.keyPhotoUrl) {
        response.push({
            original: popup.keyPhotoUrl,
            originalClass: 'popup-details-gallery__image'
        });
    }
    popup.photoUrls?.forEach((photo) => {
        response.push({
            original: photo,
            originalClass: 'popup-details-gallery__image'
        });
    });
    return response;
}

const PopupDetails = (props: PopupDetailsProps) => {
    const {t} = useTranslation();
    const applicationsRef = useRef<HTMLDivElement>(null);
    const [isApplicantsVisible, setIsApplicantsVisible] = useState(false);
    const {
        details: {
            popup,
            property
        }
    } = props;
    useEffect(() => {
        if (applicationsRef.current) {
            const observer = new IntersectionObserver(function (entries) {
                if (entries[0].isIntersecting) {
                    setIsApplicantsVisible(true);
                }
                if (!entries[0].isIntersecting) {
                    setIsApplicantsVisible(false);
                }
            }, {threshold: [0]});
            observer.observe(applicationsRef.current);
            return () => observer.disconnect();
        }
    }, [applicationsRef.current]);
    const scrollToApplications = () => {
        applicationsRef.current?.scrollIntoView({behavior: "smooth"});
    }
    return (
        <div className="popup-details-wrapper">
            <div className="popup-details-container">
                <div className="popup-details-gallery">
                    <ReactImageGallery
                        showNav={true}
                        showBullets={true}
                        showPlayButton={false}
                        items={generateImageObjects(popup)}
                    />
                </div>
                <div className="popup-details">
                    <PopupDetailsHeader details={props.details}/>
                    <PopupDetailsPrices prices={popup.prices}/>
                    <Divider/>
                    <p className="popup-details__description" dangerouslySetInnerHTML={{__html: popup.description}}/>
                    <PopupDetailsCategories popup={popup}/>
                    <PopupDetailsExtras popup={popup}/>
                    <PopupDetailsMap property={property}/>
                    <div className="popup-details__apply-cta">
                        {
                            !isApplicantsVisible ? (
                                <Button variant="contained" color="success" size="large" onClick={scrollToApplications}>
                                    <PostAddIcon/>&nbsp;{t('popup.details.page.application.scroll.to.apply.button')}
                                </Button>
                            ) : (
                                <span>
                                <PostAddIcon/>&nbsp;{t('popup.details.page.application.scroll.to.apply.button')}
                            </span>
                            )
                        }
                    </div>
                    <div ref={applicationsRef}>
                        <PopupDetailsApplication popup={popup}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupDetails;