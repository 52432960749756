import React, {ChangeEvent, DragEvent, useMemo} from 'react';
import cx from 'classnames';
import {useMutation} from "@tanstack/react-query";
import {CircularProgress} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import {v4 as uuidv4} from 'uuid';
import FileModel from "api/model/FileModel";
import {uploadFiles} from "api/actions/FileActions";
import ItemTag from "components/common/ItemTag";
import "./styles.css";
import {useTranslation} from "react-i18next";

interface FileUploadProps {
    value: FileModel[],
    onChange: (files: FileModel[]) => void,
    isError?: boolean,
}

const FileUpload = (props: FileUploadProps) => {
    const {t} = useTranslation();
    const inputId = useMemo<string>(() => uuidv4(), []);
    const {value, onChange, isError} = props;

    const {mutate, isPending} = useMutation({
        mutationFn: uploadFiles,
        onSuccess: data => {
            const resp = data.map(d => (d.status === "fulfilled" ? d.value : (d.reason as FileModel)));
            const currentFiles = value || [];
            onChange([...currentFiles, ...resp]);
        },
    });

    const removeFile = (localId: string) => {
        const newFiles = value.filter(file => file.localId !== localId);
        onChange(newFiles);
    }

    const handleFileDrop = async (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items.length > 0) {
            const files: FileModel[] = [];
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                const file = e.dataTransfer.items[i].getAsFile();
                if (file) {
                    files.push({
                        file,
                        localId: uuidv4(),
                    });
                }
            }
            const currentFilesCount = value?.length || 0;
            if (files.length > 0) {
                mutate(files.slice(0, 10 - currentFilesCount));
            }
        }
    };

    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files: FileModel[] = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                if (file) {
                    files.push({
                        file,
                        localId: uuidv4(),
                    });
                }
            }
            const currentFilesCount = value?.length || 0;
            if (files.length > 0) {
                mutate(files.slice(0, 10 - currentFilesCount));
            }
        }
    };

    const handleFileDragOver = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const classes = cx({
        'file-upload': true,
        'file-upload--error': isError
    });

    return (
        <div className={classes}>
            <input multiple id={inputId} onChange={handleFileSelect} type="file"/>
            {
                isPending && (
                    <div className="file-upload__pending">
                        <CircularProgress/>
                    </div>
                )
            }
            <label
                htmlFor={inputId}
                className="file-upload-label"
                onDrop={handleFileDrop}
                onDragOver={handleFileDragOver}
            >
                <div className="file-upload__logo">
                    <FileUploadIcon/>
                    <div>
                        {t('file.upload.text')}
                    </div>
                </div>
            </label>
            {
                value.length > 0 && (
                    <div className="file-upload__files">
                        {
                            value.map(file => (
                                <ItemTag size="S">
                                    {file.file.name}
                                    <CloseIcon
                                        fontSize="small"
                                        className="file-upload__delete"
                                        onClick={() => removeFile(file.localId)}
                                    />
                                </ItemTag>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
}

export default FileUpload;