import React from 'react';
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import environment from "lib/EnvHelper";
import EventModel from "api/model/EventModel";
import "./styles.css";

interface EventDetailsMapProps {
    event: EventModel
}

const EventDetailsMap = (props: EventDetailsMapProps) => {
    const {
        event: {
            location,
            name,
        }
    } = props;
    return (
        <div className="event-details-map">
            <APIProvider apiKey={environment.googleMapsKey}>
                <Map
                    defaultZoom={15}
                    mapTypeControl={false}
                    mapId="287a9dc34d2b207d"
                    defaultCenter={location.position}
                    fullscreenControl={false}
                    streetViewControl={false}
                >
                    <Marker
                        title={name}
                        position={location.position}
                    />
                </Map>
            </APIProvider>
        </div>
    );
}

export default EventDetailsMap;