import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import IpLocationResponse from "api/model/IpLocationResponse";
import {getLocation} from "api/actions/LocationActions";
import './styles.css';
import {CircularProgress} from "@mui/material";
import {useLocationStore} from "store/locationStore";

interface LocationWrapperProps {
    children: any
}

const LocationWrapper = (props: LocationWrapperProps) => {
    const {children} = props;
    const updateLocation = useLocationStore(state => state.updateLocation);
    const {
        data,
        isLoading,
        isError,
    } = useQuery<IpLocationResponse>({
        queryKey: ['user-location'],
        queryFn: getLocation,
        refetchOnWindowFocus: false,
    });
    const [isWrapperLoading, setIsWrapperLoading] = useState(true);
    useEffect(() => {
        if (data?.languageIso && data?.countryIso) {
            updateLocation(data);
            setIsWrapperLoading(false);
        } else if (!isLoading || isError) {
            setIsWrapperLoading(false);
        }
    }, [isWrapperLoading, data, isError]);
    return (
        <>
            {
                isWrapperLoading ? (
                    <div className="location-wrapper-loader">
                        <CircularProgress size="40px" />
                    </div>
                ) : children
            }
        </>
    );
}

export default LocationWrapper;