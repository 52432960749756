import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useSearchParams} from "react-router-dom";
import {COOKIE_LIFETIME_SECONDS} from "consts/LanguageConsts";
import {useLocationStore} from "store/locationStore";
import "./styles.css";

interface PageProps {
    children: any
}

const Page = (props: PageProps) => {
    const userLocation = useLocationStore(state => state.location);
    const [searchParams, setSearchParams] = useSearchParams();
    const urlLang = searchParams.get("lang");
    const {i18n} = useTranslation();
    const [cookies, setCookie] = useCookies(['language']);
    useEffect(() => {
        if (urlLang) {
            i18n.changeLanguage(urlLang);
            setCookie('language', urlLang, {maxAge: COOKIE_LIFETIME_SECONDS})
            searchParams.delete("lang");
            setSearchParams(searchParams);
        } else {
            if (cookies.language) {
                i18n.changeLanguage(cookies.language);
            } else if (userLocation) {
                const locationLanguage = userLocation.languageIso.toLowerCase();
                setCookie('language', locationLanguage, {maxAge: COOKIE_LIFETIME_SECONDS});
                i18n.changeLanguage(locationLanguage);
            }
        }
    }, [cookies, userLocation]);
    return (
        <div className="page">
            {props.children}
        </div>
    );
};

export default Page;