import React from 'react';
import PageWithHeader from "components/common/PageWithHeader";
import {useQuery} from "@tanstack/react-query";
import {getPopupDetails} from "api/actions/PopupActions";
import PopupDetailsModel from "api/model/PopupDetailsModel";
import {useParams} from "react-router-dom";
import PopupDetails from "components/PopupDetails";
import useTitle from "lib/useTitle";

const PopupDetailsPage = () => {
    const {popupId = ''} = useParams();
    const {
        data,
        isLoading,
    } = useQuery<PopupDetailsModel>({
        queryKey: ['popup-details-query', popupId],
        queryFn: () => getPopupDetails(parseInt(popupId, 10)),
    });
    useTitle(data && `${data?.popup.name} @ ${data?.property.name} | Provente.io`);
    return (
        <PageWithHeader searchType="popups" isLoading={isLoading}>
            {
                data && <PopupDetails details={data} />
            }
        </PageWithHeader>
    );
}

export default PopupDetailsPage;