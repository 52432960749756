import React from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FestivalIcon from "@mui/icons-material/Festival";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getSearchFilterModelFromParams, paramsToUri} from "lib/SearchHelper";
import {SearchType} from "model/SearchType";
import LanguageSelector from "components/common/LanguageSelector";
import "./styles.css";

interface PageHeaderProps {
    searchType?: SearchType
}

const PageHeader = (props: PageHeaderProps) => {
    const {searchType} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const params = useParams();
    const searchModel = getSearchFilterModelFromParams(searchParams, params);
    const switchSearchType = (searchType: SearchType) => {
        const uri = paramsToUri(searchModel.formModel);
        let target = `/search/${searchType}`;
        if (uri) {
            target = `${target}?${uri}`;
        }
        navigate(target);
    };
    const isPopupSearch = (searchModel.searchType || searchType) === 'popups';
    const isEventSearch = (searchModel.searchType || searchType) === 'events';
    return (
        <div className="page-header">
            <a href="/">
                <img
                    alt="Provente.io"
                    className="page-header__logo"
                    src="/static/logos/provente_logo_dark_green.svg"
                />
            </a>
            <div className="page-header__navigation">
                <Button
                    variant="contained"
                    onClick={() => switchSearchType('popups')}
                    color={isPopupSearch ? "primary" : "secondary"}
                >
                        <span
                            className="page-header__navigation-button-content page-header__navigation-button-content--popups">
                        <StorefrontIcon fontSize="medium"/>{t('event.search.filter.popups.button')}
                        </span>
                </Button>
                <Button
                    variant="contained"
                    onClick={() => switchSearchType('events')}
                    color={isEventSearch ? "primary" : "secondary"}
                >
                        <span
                            className="page-header__navigation-button-content page-header__navigation-button-content--events">
                            {t('event.search.filter.events.button')}<FestivalIcon fontSize="medium"/>
                        </span>
                </Button>
            </div>
            <div className="page-header__menu">
                <LanguageSelector/>
                <Link to="/join-us">
                    <Button variant="contained">
                        {t('page.header.button.join.us')}
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default PageHeader;