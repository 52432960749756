import {Cluster, ClusterStats, Marker, Renderer} from "@googlemaps/markerclusterer";
import {MarkerModel} from "components/Search/PopupSearch/PopupMapMarkerClusterer";

export default class CustomPopupClustererRenderer implements Renderer {
    markers: MarkerModel[];

    constructor(markers: MarkerModel[]) {
        this.markers = markers;
    }

    render(cluster: Cluster, stats: ClusterStats, map: google.maps.Map): Marker {
        const {count, position, markers} = cluster;
        const advancedMarkers = markers as google.maps.marker.AdvancedMarkerElement[];

        const clusterMarkers = this.markers.filter((marker) => advancedMarkers.find(adv => adv === marker.marker));
        const totalPopups = clusterMarkers.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.count;
        }, 0);

        const color =
            count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
        const svg = `
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
            <circle cx="120" cy="120" opacity=".6" r="70" />
            <circle cx="120" cy="120" opacity=".3" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="110" />
            <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-weight="500" font-size="65" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${totalPopups}</text>
            </svg>
        `;

        const title = `${totalPopups} popups`,
            zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + totalPopups;

        const parser = new DOMParser();
        const svgEl = parser.parseFromString(
            svg,
            "image/svg+xml"
        ).documentElement;
        svgEl.setAttribute("transform", "translate(0 25)");

        const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions = {
            map,
            position,
            zIndex,
            title,
            content: svgEl,
        };
        return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
    }
}